export const USER_ACTION_TYPES = {
  SET_CURRENT_USER: "user/SET_CURRENT_USER",
  CHECK_USER_SESSION: "user/CHECK_USER_SESSION",
  EMAIL_SIGN_IN_START: "user/EMAIL_SIGN_IN_START",
  AUTH_SUCCESS: "user/AUTH_SUCCESS",
  SIGN_IN_SUCCESS: "user/SIGN_IN_SUCCESS",
  SIGN_IN_FAILED: "user/SIGN_IN_FAILED",
  SIGN_UP_START: "user/SIGN_UP_START",
  SIGN_UP_SUCCESS: "user/SIGN_UP_SUCCESS",
  SIGN_UP_FAILED: "user/SIGN_UP_FAILED",
  SIGN_OUT_START: "user/SIGN_OUT_START",
  SIGN_OUT_SUCCESS: "user/SIGN_OUT_SUCCESS",
  SIGN_OUT_FAILED: "user/SIGN_OUT_FAILED",
  UPDATE_PROFILE_START: "user/UPDATE_PROFILE_START",
  UPDATE_PROFILE_SUCCESS: "user/UPDATE_PROFILE_SUCCESS",
  UPDATE_PROFILE_FAILED: "user/UPDATE_PROFILE_FAILED",
  RE_AUTH_START: "user/RE_AUTH_START",
  RE_AUTH_SUCCESS: "user/RE_AUTH_SUCCESS",
  RE_AUTH_FAILED: "user/RE_AUTH_FAILED",
  UPDATE_PASSWORD_START: "user/UPDATE_PASSWORD_START",
  UPDATE_PASSWORD_SUCCESS: "user/UPDATE_PASSWORD_SUCCESS",
  UPDATE_PASSWORD_FAILED: "user/UPDATE_PASSWORD_FAILED",
  UPDATE_EMAIL_START: "user/UPDATE_EMAIL_START",
  UPDATE_EMAIL_SUCCESS: "user/UPDATE_EMAIL_SUCCESS",
  UPDATE_EMAIL_DATABASE_SUCCESS: "user/UPDATE_EMAIL_DATABASE_SUCCESS",
  UPDATE_EMAIL_FAILED: "user/UPDATE_EMAIL_FAILED",
  RESET_PASSWORD_START: "user/RESET_PASSWORD_START",
  RESET_PASSWORD_SUCCESS: "user/RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_FAILED: "user/RESET_PASSWORD_FAILED",
};
