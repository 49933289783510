import { Fragment } from "react";
import ForgetPassword from "../../components/forget-password/forget-password.component";
import {
  Logo,
  LogoContainer,
  SignInBg,
  SignInCard,
  SignInContainer,
} from "../sign-in/sign-in.styles";

const ForgetPwd = () => {
  return (
    <Fragment>
      <SignInContainer>
        <LogoContainer to="/">
          <Logo />
        </LogoContainer>
        <SignInCard>
          <ForgetPassword />
        </SignInCard>
        <SignInBg />
      </SignInContainer>
    </Fragment>
  );
};
export default ForgetPwd;
