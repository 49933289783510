import "./checkout.styles";
import {
  CheckOutContainer,
  CheckoutContext,
  LogoContainer,
  Logo,
  SiginContainer,
} from "./checkout.styles";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../store/user/user.selector";

import { SignInBg, SignInCard } from "../sign-in/sign-in.styles";
import CheckoutForm from "../../components/checkout-form/checkout-form.component";

import SignInForm from "../../components/sign-in-form/sign-in-form.component";
import { Fragment } from "react";

const Checkout = () => {
  const currentUser = useSelector(selectCurrentUser);
  return (
    <CheckOutContainer>
      <LogoContainer to="/">
        <Logo />
      </LogoContainer>

      {currentUser ? (
        <Fragment>
          <CheckoutContext>
            <CheckoutForm />
          </CheckoutContext>
          <SignInBg />
        </Fragment>
      ) : (
        <SiginContainer>
          <SignInCard>
            <SignInForm nextpath="checkout" />
          </SignInCard>
          <SignInBg />
        </SiginContainer>
      )}
    </CheckOutContainer>
  );
};
export default Checkout;

// {currentUser ? (
//   <SignInNavLink as="span" onClick={signOutUser}>
//     Sign Out
//   </SignInNavLink>
// ) : (
//   <SignInNavLink to="/signin">Sign In</SignInNavLink>
// )}
