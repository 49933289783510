import { Fragment } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectError } from "../../../store/user/user.selector";

import FormInput from "../../form-input/form-input.component";
import {
  ClickLink,
  ErrorContext,
  SignInNForgot,
} from "../../sign-in-form/sign-in-form.styles";
import { LoginModalContainer } from "./verify-login.styles";

const LoginModal = ({ formData, setFormData }) => {
  // const userError = useSelector(selectError);
  // const [formFields, setFormFields] = useState(defaultFormFields);
  // const { email, password } = formFields;
  // const navigate = useNavigate();
  // const [IsSignUp, setIsSignUp] = useState(false);
  const navigate = useNavigate();

  // const resetFormFields = () => {
  //   setFormFields(defaultFormFields);
  // };

  // const handleSubmit = () => {};

  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormData({ ...formData, [name]: value });
  };
  return (
    <Fragment>
      <LoginModalContainer>
        <div>
          <FormInput
            label="Email"
            type="email"
            required
            onChange={handleChange}
            name="email"
            value={formData.email}
            disabled
            style={{ backgroundColor: "#cbcbcb", color: "#696969" }}

            // "background-color: gray; color: #0b1538;"}
          />

          <FormInput
            label="Password"
            type="password"
            required
            onChange={handleChange}
            name="password"
            value={formData.password}
          />
          <SignInNForgot>
            <ClickLink
              onClick={() => {
                // setIsForgetPass(true);
                navigate("/forget");
              }}
            >
              Forget Password
            </ClickLink>
          </SignInNForgot>
        </div>
      </LoginModalContainer>
    </Fragment>
  );
};
export default LoginModal;

// <ButtonsContainer>
//   <Button type="submit">Next</Button>
// </ButtonsContainer>;
