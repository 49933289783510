import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";

import NavbarDashboard from "../../components/navbar-dashboard/navbar-dashboard.component";

import Sidebar from "../../components/sidebar/sidebar.component";

import { getUserOrderHistory } from "../../store/orders/orders.action";

import { selectCurrentUser } from "../../store/user/user.selector";
import { DashboardContainer, DashboardContent } from "./user-dashboard.styles";

const UserDashboard = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);

  // console.log(orderHistory);
  // console.log(currentUser.uid);

  useEffect(() => {
    // console.log(currentUser.uid);
    dispatch(getUserOrderHistory(currentUser.uid));

    //
  }, []);

  return (
    <DashboardContainer>
      <Sidebar />
      <DashboardContent>
        <NavbarDashboard />
        <Outlet />
      </DashboardContent>
    </DashboardContainer>
  );
};
export default UserDashboard;

// style={{ height: "100%", overflow: "scroll" }}
