// import "./App.css";
// import * as React from "react";
import { useEffect } from "react";
import { Routes, Route } from "react-router-dom";

import Navigation from "./components/navigation/navigation.component";
import Home from "./pages/home/home.component";
import About from "./pages/about/about.component";
import Contact from "./pages/contact/contact.component";
import SignIn from "./pages/sign-in/sign-in.component";
import SignUp from "./pages/sign-up/sign-up.component";

import { checkUserSession } from "./store/user/user.action";
import { useDispatch } from "react-redux";
import Checkout from "./pages/checkout/checkout.component";
import UserDashboard from "./pages/user-dashboard/user-dashboard.component";
import Orders from "./components/orders/orders.component";
import UserProfile from "./components/user-profile/user-profile.component";
import HomeDashboard from "./components/home-dashboard/home-dashboard.component";
import Privacy from "./pages/privacy/privacy.component";
import Terms from "./pages/terms/terms.component";
import WritingPackage from "./pages/writing-package/writing-package.component";
import FAQS from "./pages/faqs/faqs.component";
import ForgetPwd from "./pages/forget/forget.component";

import GuardedRoutes from "./components/guarded-routes/guarded-routes.component";
import GuardedCheckout from "./components/guard-checkout/guard-checkout.component";

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(checkUserSession());
    // dispatch(fetchProductsStart());
  }, []);

  // useEffect(() => {}, []);

  return (
    <Routes>
      <Route path="/" element={<Navigation />}>
        <Route index element={<Home />} />
        <Route path="about" element={<About />} />
        <Route path="contact" element={<Contact />} />
        <Route path="price" element={<WritingPackage />} />
        <Route path="privacy" element={<Privacy />} />
        <Route path="terms" element={<Terms />} />
        <Route path="faqs" element={<FAQS />} />
      </Route>
      <Route path="signin" element={<SignIn />} />
      <Route path="forget" element={<ForgetPwd />} />
      <Route path="signup" element={<SignUp />} />
      <Route element={<GuardedCheckout />}>
        <Route path="checkout" element={<Checkout />} />
      </Route>
      <Route element={<GuardedRoutes />}>
        <Route path="account/*" element={<UserDashboard />}>
          <Route element={<HomeDashboard />} />
          <Route index element={<Orders />} />
          <Route path="profile" element={<UserProfile />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default App;
