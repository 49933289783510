import styled from "styled-components";

export const FormInputLabel = styled.div``;

export const Input = styled.input``;

export const Group = styled.label`
  font-size: 15px;
`;

export const NavLink = styled.a`
  color: #2b56f3;
  cursor: pointer;
`;
