import { Fragment } from "react";
import Footer from "../../components/footer/footer.component";
import PricePage from "../../components/home/price-page/price-page.component";
import {
  HeaderContainer,
  HeaderContent,
  PageTitle,
  ContactFormContainer,
  ButtonsContainer,
  HeaderBg,
  SectionBg,
  ContextStyle,
  FaqsContainer,
  FaqsContent,
  FormGroup,
  Textarea,
  ContactCardContainer,
} from "./writing-package.styles";

const WritingPackage = () => {
  return (
    <Fragment>
      <HeaderContainer className="intro">
        <HeaderContent>
          <PageTitle>
            <h1>Writing Package</h1>
          </PageTitle>
          <PricePage />
        </HeaderContent>
        <HeaderBg />
        <SectionBg />
      </HeaderContainer>
      <Footer />
    </Fragment>
  );
};
export default WritingPackage;
