import "./home.styles.scss";

import Experience from "../../components/home/experience/experience.component";
import Header from "../../components/home/header/header.component";
import Steps from "../../components/home/steps/steps.component";
import PricePage from "../../components/home/price-page/price-page.component";
import Footer from "../../components/footer/footer.component";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchProductsStart } from "../../store/product/product.action";

const Home = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    // dispatch(checkUserSession());
    dispatch(fetchProductsStart());
  }, []);
  return (
    <div className="home-container">
      <Header />
      <Steps />
      <Experience />
      <PricePage bgcolor="#f6f9fc" />
      <Footer />
    </div>
  );
};

export default Home;
