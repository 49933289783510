import {
  NavbarContainer,
  Wrapper,
  TitileContainer,
  RightWrapper,
  LeftWrapper,
} from "./navbar-dashboard.styled";

const NavbarDashboard = () => {
  return (
    <NavbarContainer>
      <Wrapper>
        <LeftWrapper>
          <TitileContainer>
            <h1>Dashboard</h1>
          </TitileContainer>
        </LeftWrapper>
        <RightWrapper></RightWrapper>
      </Wrapper>
    </NavbarContainer>
  );
};
export default NavbarDashboard;

// <Item>
// <DisplayName>Modern LastName</DisplayName>
// </Item>

// <SearchContainer>
// <input type="text" placeholder="Search..." />
// <SearchOutlinedIcon className="searchIcon" />
// </SearchContainer>
