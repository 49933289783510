import { Fragment, useState } from "react";
import { useLocation } from "react-router-dom";
import SignInForm from "../../components/sign-in-form/sign-in-form.component";

import {
  SignInContainer,
  SignInBg,
  SignInCard,
  Logo,
  LogoContainer,
} from "./sign-in.styles";

const SignIn = ({ path }) => {
  // const Nextpath = "";
  // if (path === "/checkout") {
  //   setNextPath(path);
  //   // Nextpath = Homepath;
  //   console.log(NextPath);
  // } else {
  //   setNextPath("/");
  //   // setNextPath(path);
  //   console.log(NextPath);
  // }

  return (
    <Fragment>
      <SignInContainer>
        <LogoContainer to="/">
          <Logo />
        </LogoContainer>
        <SignInCard>
          <SignInForm />
        </SignInCard>
      </SignInContainer>
      <SignInBg />
    </Fragment>
  );
};
export default SignIn;
