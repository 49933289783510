import styled from "styled-components";
const subColor = "grey";
const mainColor = "black";

// export const DropdownWrapper = styled.div`
//   display: flex;
//   flex-flow: column;
//   /* justify-content: flex-start; */
// `;

export const Group = styled.div`
  position: relative;
  margin: 45px 0;
`;

export const StyledSelect = styled.select`
  max-width: 100%;
  height: 100%;
  /* padding: 0.5rem; */
  color: ${subColor};
  margin-bottom: 1rem;
  background: none;
  background-color: white;
  font-size: 18px;
  padding: 10px 10px 10px 5px;
  display: block;
  width: 100%;
  border: none;
  border-radius: 0;
  border: none;
  /* margin: 25px 0; */
`;

export const StyledOption = styled.option`
  color: ${(props) => (props.selected ? "lightgrey" : "black")};
`;

export const StyledLabel = styled.label`
  margin-bottom: 1rem;
`;
