import styled from "styled-components";

export const ProfileContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: 80px;
  /* padding: 0.5rem calc((100vw - 1200px) / 2); */
  height: calc(100vh - 80px);
  /* height: 100%;
  width: 100%; */
  overflow-y: scroll;
  @media screen and (max-width: 768px) {
    margin-top: 60px;
    height: calc(100vh - 60px);
  }
`;

export const ProfileCard = styled.div`
  width: 90%;
  height: auto;
  border-radius: 25px;
  background-color: white;
  display: grid;
  grid-gap: 20px;

  grid-template-columns: repeat(2, 1fr);
  padding: 30px;

  @media screen and (max-width: 768px) {
    /* top: 300px; */
    height: auto;
    /* overflow: scroll; */
    grid-template-columns: 1fr;
    width: 80%;
    padding: 30px;
  }
  @media screen and (max-width: 768px) {
    padding: 20px;
    width: 90%;
  }
`;

export const ProfileContent = styled.div`
  width: 100%;
  height: 100%;
  padding: 30px;
  border: 1px solid rgba(112, 112, 112, 0.2);
`;

export const ProfileTitle = styled.h2`
  font-size: 30px;
  color: #0b1538;
  @media screen and (max-width: 1024px) {
    font-size: 25px;
  }
  @media screen and (max-width: 768px) {
    font-size: 20px;
  }
`;
