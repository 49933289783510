import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Outlet, Navigate, useLocation } from "react-router-dom";
import { selectAuth, selectCurrentUser } from "../../store/user/user.selector";

const GuardedRoutes = () => {
  const location = useLocation();
  const isAuth = useSelector(selectCurrentUser);
  return isAuth ? (
    <Outlet />
  ) : (
    <Navigate to="/signin" state={{ from: location }} replace />
  );
};

export default GuardedRoutes;
