export const ORDER_ACTION_TYPES = {
  SAVE_ORDER_HISTORY_START: "orders/SAVE_ORDER_HISTORY_START",
  SAVE_ORDER_HISTORY_FAILED: "user/SAVE_ORDER_HISTORY_FAILED",
  GET_USER_ORDER_HISTORY_START: "orders/GET_USER_ORDER_HISTORY_START",
  GET_USER_ORDER_HISTORY_SUCCESS: "orders/GET_USER_ORDER_HISTORY_SUCCESS",
  GET_USER_ORDER_HISTORY_FAILED: "orders/GET_USER_ORDER_HISTORY_FAILED",
  SET_USER_ORDER_HISTORY: "orders/SET_USER_ORDER_HISTORY",
  UPDATE_STATUS_ORDER_START: "orders/UPDATE_STATUS_ORDER_START",
  UPDATE_STATUS_ORDER_SUCCESS: "orders/UPDATE_STATUS_ORDER_SUCCESS",
  UPDATE_STATUS_ORDER_FAILED: "orders/UPDATE_STATUS_ORDER_FAILED",
  CLEAR_ERROR_ORDER: "orders/CLEAR_ERROR_ORDER",
};
