import { ORDER_ACTION_TYPES } from "./orders.types";

const INITIAL_STATE = {
  orderHistory: [],
  // orderDetails: {},
  isLoading: false,
  error: null,
};

export const ordersReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case ORDER_ACTION_TYPES.SET_USER_ORDER_HISTORY:
      // case ORDER_ACTION_TYPES.UPDATE_STATUS_ORDER_SUCCESS:
      return { ...state, orderHistory: payload, error: null, isLoading: false };
    case ORDER_ACTION_TYPES.GET_USER_ORDER_HISTORY_START:
      return { ...state, error: null, isLoading: true };
    // case ORDER_ACTION_TYPES.GET_USER_ORDER_HISTORY_SUCCESS:
    //   return { ...state, isLoading: true };
    // case USER_ACTION_TYPES.SIGN_OUT_SUCCESS:
    //   return { ...state, currentUser: null };
    // case ORDER_ACTION_TYPES.UPDATE_STATUS_ORDER_SUCCESS:
    //   return { ...state, currentUser: null };
    case ORDER_ACTION_TYPES.GET_USER_ORDER_HISTORY_FAILED:
    case ORDER_ACTION_TYPES.SAVE_ORDER_HISTORY_FAILED:
      return { ...state, error: payload, isLoading: false };
    case ORDER_ACTION_TYPES.CLEAR_ERROR_ORDER:
      return { ...state, error: null, isLoading: false };
    default:
      return state;
  }
};
