import { SpinnerContainer, SpinnerOverlay } from "./spinner.styles";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
const Spinner = () => (
  <SpinnerOverlay>
    <Box sx={{ display: "flex" }}>
      <CircularProgress color="inherit" size={30} />
    </Box>
  </SpinnerOverlay>
);

export default Spinner;

//    <SpinnerContainer />
