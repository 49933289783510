import styled from "styled-components";
import { Link } from "react-router-dom";
import { ReactComponent as CoaonLogo } from "../../assets/coaon_logo.svg";

export const FooterContainer = styled.div`
  height: auto;
  background-color: #f6f9fc;
  padding: 50px 15% 30px 15%;
  display: grid;
  color: #697785;
  grid-template: 1fr / repeat(4, 1fr);
  bottom: 0;
  @media screen and (max-width: 425px) {
    grid-template: repeat(4, 1fr) / 1fr;
    /* grid-area: 2 /1 /2 /5; */
  }
`;

export const LogoContainer = styled.div`
  /* width: 100%; */
`;

export const LogoLink = styled(Link)`
  width: 100%;
  @media screen and (max-width: 425px) {
    display: flex;
    justify-content: center;
  }
`;

export const CompanyInfo = styled.div`
  /* grid-template-rows: repeat(2, 1fr); */
`;
export const ResourceInfo = styled.div`
  /* grid-template-rows: repeat(2, 1fr); */
`;

export const ProductInfo = styled.div`
  /* grid-template-rows: repeat(2, 1fr); */
`;

export const SocialContainer = styled.div`
  /* grid-template: repeat(2, 1fr) / 1fr; */
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  justify-content: end;
  @media screen and (max-width: 768px) {
    justify-content: center;
  }
`;
export const SocialIcon = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;

  & .social-icon {
    color: #697785;

    &:hover {
      color: #061e5c;
      cursor: pointer;
    }
  }
  @media screen and (max-width: 1024px) {
    /* font-size: 16px; */
  }

  @media screen and (max-width: 768px) {
    /* width: 8px; */
    .social-icon {
      width: 18px;
    }
  }

  /* grid-template-rows: repeat(2, 1fr); */
`;
export const EndContainer = styled.div`
  margin-top: 30px;
  border-top: 2px solid #eceef5;
  grid-area: 2 /1 /2 /5;
  padding: 30px 0;
  width: 100%;
  display: flex;
  justify-content: center;
  @media screen and (max-width: 1024px) {
    font-size: 12px;
  }
  @media screen and (max-width: 425px) {
    grid-area: 5 /1 /5 /1;
  }
`;

export const TopicName = styled.h3`
  color: #0b1538;
  text-transform: uppercase;
  font-size: 16px;
  @media screen and (max-width: 1024px) {
    font-size: 14px;
  }
  @media screen and (max-width: 425px) {
    text-align: center;
    /* margin: auto; */
  }
`;

export const NavLinks = styled.div`
  width: 100%;
  height: 100%;
  align-items: center;
  display: block;
  @media screen and (max-width: 1024px) {
    font-size: 12px;
  }
  @media screen and (max-width: 425px) {
    text-align: center;
    /* margin: auto; */
  }
  /* justify-content: flex-end; */
`;

export const NavLink = styled(Link)`
  padding: 10px 0;
  cursor: pointer;
  font-weight: 400;
  color: #697785;
  display: block;

  &:hover {
    color: #8a9db1;
  }

  /* justify-content: center;
  align-items: center; */
`;

export const Logo = styled(CoaonLogo)`
  width: 90px;
  float: right;
  @media screen and (max-width: 1024px) {
    /* font-size: 16px; */
  }

  @media screen and (max-width: 768px) {
    width: 70px;
  }
  @media screen and (max-width: 425px) {
    float: none;

    /* margin: auto; */
  }
  /* justify-content: center;
  align-items: center; */
`;
