import * as yup from "yup";

export const uploadSchema = yup.object().shape({
  upload: yup
    .mixed()
    // .test("required", "Please upload your document", (value) => {
    //   if (!value || value.length === 0) return false;

    //   console.log("require");
    //   return true;
    // })
    .required("Please upload your document")
    .test("fileSized", "You cannot upload a file larger than 5MB", (value) => {
      // console.log(value);
      if (!value || value.length === 0) return true;
      return value && value[0].size <= 5000000;
    })
    .test("type", "Your document is not in supporting format", (value) => {
      if (!value || value.length === 0) return true;
      return (
        value &&
        (value[0].type === "application/msword" ||
          (value && value[0].type === "application/pdf") ||
          value[0].type ===
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document")
      );
    }),
});
// const schema = yup.object().shape({
//   firstName: yup.string().required("First Name should be required please"),
//   lastName: yup.string().required(),
//   email: yup.string().email().required(),
//   age: yup.number().positive().integer().required(),
//   password: yup.string().min(4).max(15).required(),
//   confirmPassword: yup.string().oneOf([yup.ref("password"), null]),
// });
export const signInSchema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().required(),
});

export const forgetPasswordSchema = yup.object().shape({
  email: yup.string().email().required(),
});

const passwordRules = new RegExp(
  "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,20})"
);

const phoneRules = new RegExp("([+]?\\d{1,2}[.-s]?)?(\\d{3}[.-]?){2}\\d{4}");

export const signUpSchema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup
    .string()
    .min(8)
    .matches(passwordRules, {
      message:
        " - At least 8 characters \n - At least one (1) uppercase \n - At least one (1) lowercase \n - At least one (1) Number\n - At least (1) special case character [!@#$%^&*] \n - Maximum 20 characters",
    })
    .required(),
  retypepassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Password must match")
    .required(),
  firstname: yup.string().required(),
  middlename: yup.string(),
  lastname: yup.string().required(),
  phone: yup.string().matches(phoneRules, {
    message: "accepted phone formats: +XX XXX-XXX-XXXX  ",
  }),
  terms: yup.boolean(),
  newsletter: yup.boolean(),
});

export const updateProfleSchema = yup.object().shape({
  email: yup.string().email().required(),
  firstname: yup.string().required(),
  middlename: yup.string(),
  lastname: yup.string().required(),
  phone: yup.string().matches(phoneRules, {
    message: "accepted phone formats: +XX XXX-XXX-XXXX  ",
  }),
});
