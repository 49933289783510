import { takeLatest, all, call, put } from "redux-saga/effects";
import { emptyCart } from "../cart/cart.action";
import {
  getUserOrderHistoryFailed,
  saveOrderHistoryFailed,
  setUserOrderHistory,
  updateStatusOrderFailed,
  updateStatusOrderSuccess,
} from "./orders.action";
import {
  addOrder,
  handdleUpdateStatus,
  handleGetUserOrderHistory,
} from "./orders.helpers";
import { ORDER_ACTION_TYPES } from "./orders.types";

export function* saveOrder({
  payload: {
    collectionKey,
    objectsToAdd,
    orderId,
    userId,
    firstname,
    lastname,
    email,
    phone,
    amount,
    cartItems,
  },
}) {
  try {
    yield call(
      addOrder,
      collectionKey,
      objectsToAdd,
      orderId,
      userId,
      firstname,
      lastname,
      email,
      phone,
      amount,
      cartItems
    );
    // yield put(emptyCart());
  } catch (error) {
    yield put(saveOrderHistoryFailed(error));
  }
}

// export function* signUp({ payload: { email, password, additionalDetails } }) {
//   try {
//     const { user } = yield call(
//       createAuthUserWithEmailAndPassword,
//       email,
//       password
//     );

//     yield put(signUpSuccess(user, additionalDetails));
//   } catch (error) {
//     yield put(signUpFailed(error));
//   }
// }
export function* getUserOrderHistory({ payload }) {
  try {
    // console.log(payload);
    const history = yield call(handleGetUserOrderHistory, payload);

    yield put(setUserOrderHistory(history));
  } catch (error) {
    yield put(getUserOrderHistoryFailed(error));
  }
}

export function* updateStatus({ payload: { orderId, status, uid } }) {
  try {
    yield call(handdleUpdateStatus, orderId, status);
    // yield put(
    //   updateStatusOrderSuccess({ id: history.data().id, ...history.data() })
    // );
    yield put(updateStatusOrderSuccess(uid));
    // yield put(refreshData(uid));
  } catch (error) {
    yield put(updateStatusOrderFailed(error));
  }
}

export function* refreshData({ payload }) {
  try {
    const history = yield call(handleGetUserOrderHistory, payload);
    yield put(setUserOrderHistory(history));
  } catch (error) {
    yield put(updateStatusOrderFailed(error));
  }
}

export function* onGetUserOrderHistoryastart() {
  yield takeLatest(
    ORDER_ACTION_TYPES.GET_USER_ORDER_HISTORY_START,
    getUserOrderHistory
  );
}

export function* onSaveOrderHistoryStart() {
  yield takeLatest(ORDER_ACTION_TYPES.SAVE_ORDER_HISTORY_START, saveOrder);
}

export function* onUpdateStatusStart() {
  yield takeLatest(ORDER_ACTION_TYPES.UPDATE_STATUS_ORDER_START, updateStatus);
}

export function* onUpdateStatusSuccess() {
  yield takeLatest(ORDER_ACTION_TYPES.UPDATE_STATUS_ORDER_SUCCESS, refreshData);
}

// export function* onClearErrorOrderStart() {
//   yield takeLatest(ORDER_ACTION_TYPES.CLEAR_ERROR_ORDER, clearError);
// }

export function* ordersSagas() {
  yield all([
    call(onSaveOrderHistoryStart),
    call(onGetUserOrderHistoryastart),
    call(onUpdateStatusStart),
    call(onUpdateStatusSuccess),
    // call(onClearErrorOrderStart),
  ]);
}
