import OrderTable from "../orderTable/orderTable.component";

const Orders = () => {
  return (
    <div>
      <OrderTable />
    </div>
  );
};
export default Orders;
