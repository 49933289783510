import { useState, FormEvent, ChangeEvent, useEffect } from "react";

import FormInput from "../form-input/form-input.component";

import Button, { BUTTON_TYPE_CLASSES } from "../button/button.component";

import {
  SignUpContainer,
  ButtonsContainer,
  GroupName,
  FormGroup,
  GroupInput,
  GroupCheck,
  ShortNote,
  NavLink,
} from "./sign-up-form.styles";
import CheckedInput from "../checked-input/checked-input.component";
import {
  createAuthUserWithEmailAndPassword,
  createUserDocumentFromAuth,
} from "../../utils/firebase/firebase.utils";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { signUpStart } from "../../store/user/user.action";
import { useFormik } from "formik";
import { signUpSchema } from "../../validations/uploadValidation";
import {
  selectCurrentUser,
  selectCurrentUserIsLoading,
  selectError,
  selectErrorSignUp,
} from "../../store/user/user.selector";
import Spinner from "../spinner/spinner.component";
import { ErrorContext } from "../sign-in-form/sign-in-form.styles";
import ErrorOutlinedIcon from "@mui/icons-material/ErrorOutlined";

const defaultFormFields = {
  email: "",
  password: "",
  retypepassword: "",
  firstname: "",
  middlename: "",
  lastname: "",
  phone: "",
  terms: true,
  newsletter: true,
};

const SignUpForm = ({ nextpath }) => {
  const navigate = useNavigate();
  const [formFields, setFormFields] = useState(defaultFormFields);
  const [isSignUp, setIsSignUp] = useState(false);
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);
  const isLoading = useSelector(selectCurrentUserIsLoading);
  const errorSignup = useSelector(selectErrorSignUp);
  const errorSignin = useSelector(selectError);

  useEffect(() => {
    // console.log(currentUser.uid);
    if (currentUser) {
      if (nextpath) {
        navigate(nextpath);
      } else {
        navigate("/account");
      }
    }

    //
  }, [currentUser]);

  const onSubmit = async (values, actions) => {
    // event.preventDefault();

    if (values.password !== values.retypepassword) {
      // alert("password do not match");
      return;
    }

    try {
      setIsSignUp(true);
      dispatch(
        signUpStart(values.email, values.password, {
          displayName: values.firstname,
          firstname: values.firstname,
          middlename: values.middlename,
          lastname: values.lastname,
          phone: values.phone,
          terms: values.terms,
          newsletter: values.newsletter,
        })
      );

      // const { user } = await createAuthUserWithEmailAndPassword(
      //   email,
      //   password
      // );

      // await createUserDocumentFromAuth(user, {
      //   displayName: firstname,
      //   firstname,
      //   middlename,
      //   lastname,
      //   phone,
      //   terms,
      //   newsletter,
      // });
      // dispatch(emailSignInStart(email, password));
      actions.resetForm();
      setIsSignUp(false);

      // navigate(nextpath);
    } catch (error) {
      // if (error.code === "auth/email-already-in-use") {
      //   alert("Cannot create user, email already in use");
      // } else {
      //   console.log("user creation encounterred an error", error);
      // }
    }
  };

  // const handleChange = (event) => {
  //   const { name, value } = event.target;

  //   setFormFields({ ...formFields, [name]: value });

  // };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: {
        email: "",
        password: "",
        retypepassword: "",
        firstname: "",
        middlename: "",
        lastname: "",
        phone: "",
        terms: true,
        newsletter: true,
      },
      validationSchema: signUpSchema,
      onSubmit,
    });

  return (
    <SignUpContainer>
      <h2>sign up</h2>
      <h1>Register Today, for Free</h1>
      <p>Explore our services without limitation</p>
      <form onSubmit={handleSubmit}>
        <GroupName>
          <FormGroup>
            <FormInput
              label="First Name"
              type="text"
              required
              onChange={handleChange}
              name="firstname"
              value={values.firstname}
              onBlur={handleBlur}
              className={
                errors.firstname && touched.firstname ? "input-error" : ""
              }
            />
            {errors.firstname && touched.firstname && (
              <div className="error">{errors.firstname}</div>
            )}
          </FormGroup>

          <FormGroup>
            <FormInput
              label="Middle Name"
              type="text"
              onChange={handleChange}
              name="middlename"
              value={values.middlename}
              onBlur={handleBlur}
              className={
                errors.middlename && touched.middlename ? "input-error" : ""
              }
            />
            {errors.middlename && touched.middlename && (
              <div className="error">{errors.middlename}</div>
            )}
          </FormGroup>

          <FormGroup>
            <FormInput
              label="Last Name"
              type="text"
              required
              onChange={handleChange}
              name="lastname"
              value={values.lastname}
              onBlur={handleBlur}
              className={
                errors.lastname && touched.lastname ? "input-error" : ""
              }
            />
            {errors.lastname && touched.lastname && (
              <div className="error">{errors.lastname}</div>
            )}
          </FormGroup>
        </GroupName>
        <GroupInput>
          <FormGroup>
            <FormInput
              label="Email"
              type="email"
              required
              onChange={handleChange}
              name="email"
              value={values.email}
              onBlur={handleBlur}
              className={errors.email && touched.email ? "input-error" : ""}
            />
            {errors.email && touched.email && (
              <div className="error">{errors.email}</div>
            )}
          </FormGroup>

          <FormGroup>
            <FormInput
              label="Phone"
              type="tel"
              onChange={handleChange}
              name="phone"
              value={values.phone}
              onBlur={handleBlur}
              className={errors.phone && touched.phone ? "input-error" : ""}
            />
            {errors.phone && touched.phone && (
              <div className="error">{errors.phone}</div>
            )}
          </FormGroup>

          <FormGroup>
            <FormInput
              label="Password"
              type="password"
              required
              onChange={handleChange}
              name="password"
              value={values.password}
              onBlur={handleBlur}
              className={
                errors.password && touched.password ? "input-error" : ""
              }
            />
            {errors.password && touched.password && (
              <div className="error" style={{ whiteSpace: "pre-wrap" }}>
                {errors.password}
              </div>
            )}
          </FormGroup>

          <FormGroup>
            <FormInput
              label="Comfirm Password"
              type="password"
              required
              onChange={handleChange}
              name="retypepassword"
              value={values.retypepassword}
              onBlur={handleBlur}
              className={
                errors.retypepassword && touched.retypepassword
                  ? "input-error"
                  : ""
              }
            />
            {errors.retypepassword && touched.retypepassword && (
              <div className="error">{errors.retypepassword}</div>
            )}
          </FormGroup>
        </GroupInput>

        <ShortNote>
          By signing up, you agree to the{" "}
          <NavLink to="/terms">Terms and Conditions</NavLink> and{" "}
          <NavLink to="/privacy">Privacy Policy</NavLink>
        </ShortNote>
        <ButtonsContainer>
          <Button isLoading={isSignUp} type="submit">
            {isLoading ? <Spinner /> : <span>Sign Up</span>}
          </Button>
        </ButtonsContainer>
      </form>
      {errorSignup && (
        <ErrorContext>
          <ErrorOutlinedIcon />
          <div>{errorSignup}</div>
        </ErrorContext>
      )}
    </SignUpContainer>
  );
};
export default SignUpForm;

// <GroupCheck>
// <CheckedInput
//   label="I accept COAON’s "
//   link="Terms & Conditions"
//   value={terms}
//   type="checkbox"
//   name="terms"
//   required
//   onChange={handleTermChange}
// />
// <CheckedInput
//   label="Subscribe to"
//   link="Newsletter"
//   value={newsletter}
//   type="checkbox"
//   name="newsletter"
//   onChange={handleNewsChange}
// />
// </GroupCheck>

// <SignUpNForgot>
// <NavLink to="/signup">Register</NavLink>
// <NavLink to="/forget">Forget Password</NavLink>
// </SignUpNForgot>
