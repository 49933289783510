import {
  SidebarContainer,
  Top,
  Center,
  Bottom,
  LogoContainer,
  Logo,
  NavLinks,
  LinkSpan,
  Link,
} from "./sidebar.styles";

import { signOutUser } from "../../utils/firebase/firebase.utils";
import { useNavigate } from "react-router-dom";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import { useDispatch } from "react-redux";
import { signOutStart } from "../../store/user/user.action";

const Sidebar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const signoutHandler = () => {
    dispatch(signOutStart());
    navigate("/");
  };

  return (
    <SidebarContainer>
      <Top>
        <LogoContainer to="/">
          <Logo />
        </LogoContainer>
      </Top>
      <Center>
        <ul>
          <Link
            to="/account/profile"
            className={({ isActive }) => (isActive ? "active" : "nonactive")}
          >
            <li>
              <PermIdentityOutlinedIcon className="icon" />
              <LinkSpan className="linkspan">Profile</LinkSpan>
            </li>
          </Link>

          <Link
            to="/account/"
            className={({ isActive }) => (isActive ? "active" : "nonactive")}
          >
            <li>
              <ShoppingBagOutlinedIcon className="icon" />
              <LinkSpan className="linkspan">Orders</LinkSpan>
            </li>
          </Link>
        </ul>
      </Center>
      <Bottom>
        <ul>
          <Link as="span" onClick={signoutHandler}>
            <li>
              <LogoutOutlinedIcon className="icon" />
              <LinkSpan className="linkspan">Log out</LinkSpan>
            </li>
          </Link>
        </ul>
      </Bottom>
    </SidebarContainer>
  );
};
export default Sidebar;

// <Link
//             to="/account/"
//             className={({ isActive }) => (isActive ? "active" : "")}
//           >
//             <li>
//               <HomeOutlinedIcon className="icon" />
//               <span>Dashboard</span>
//             </li>
//           </Link>
