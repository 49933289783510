import { Fragment } from "react";
import FormInput from "../../form-input/form-input.component";

const UpdatePasswordForm = ({ formData, setFormData }) => {
  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormData({ ...formData, [name]: value });
  };
  return (
    <Fragment>
      <FormInput
        label="New Password"
        type="password"
        required
        onChange={handleChange}
        name="newpassword"
        value={formData.newpassword}
      />

      <FormInput
        label="Comfirm New Password"
        type="password"
        required
        onChange={handleChange}
        name="comfirmnewpassword"
        value={formData.comfirmnewpassword}
      />
    </Fragment>
  );
};
export default UpdatePasswordForm;
