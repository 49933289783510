import { Fragment } from "react";
import FormInput from "../../form-input/form-input.component";

const UpdateEmailForm = ({ formData, setFormData }) => {
  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormData({ ...formData, [name]: value });
  };
  return (
    <Fragment>
      <FormInput
        label="Email"
        type="email"
        required
        onChange={handleChange}
        name="newemail"
        value={formData.newemail}
      />
    </Fragment>
  );
};
export default UpdateEmailForm;
