import { Fragment, useEffect, useState } from "react";

import PriceTable from "../../price-table/price-table.component";

import {
  PriceContainer,
  ContextStyle,
  PriceCardContainer,
} from "./price-page.styles";

import { useSelector } from "react-redux";
import PriceCard from "../../price-card/price-card.component";

import {
  selectProductsIsLoading,
  selectProductsMap,
} from "../../../store/product/product.selector";
import Spinner from "../../spinner/spinner.component";

const PricePage = (bgcolor) => {
  const isLoading = useSelector(selectProductsIsLoading);
  const productsMap = useSelector(selectProductsMap);
  const [products, setProducts] = useState(productsMap);
  useEffect(() => {
    setProducts(productsMap);
  }, [productsMap]);
  return (
    <PriceContainer style={{ backgroundColor: bgcolor.bgcolor }}>
      <ContextStyle>
        <h2>Our Products</h2>
        <h1>Pick a Solution for Your Job Application</h1>
        <p>
          No matter what position you choose, COAON will help you win
          recruiters’ attention
        </p>
      </ContextStyle>
      {isLoading ? (
        <Spinner />
      ) : (
        <PriceCardContainer>
          {Object.keys(products)
            .sort((a, b) => (products[a].id > products[b].id ? 1 : -1))
            .map((item) => (
              <Fragment key={item}>
                <PriceCard
                  key={products[item].id}
                  product={products[item]}
                  productType={products[item].type}
                />
              </Fragment>
            ))}
        </PriceCardContainer>
      )}

      <PriceTable />
    </PriceContainer>
  );
};
export default PricePage;
