import styled from "styled-components";

export const ContactCardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 50px;
  width: 80%;
  margin: auto;
  /* padding: 0 100px; */
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
  @media screen and (max-width: 686px) {
    width: 80%;
    grid-template-columns: 1fr;
  }
`;

export const Card = styled.div`
  padding: 20px;
  height: 350px;
  background-color: white;
  box-shadow: 0px 24px 44px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 768px) {
    height: 330px;
  }
  @media screen and (max-width: 425px) {
    height: 300px;
  }
`;

export const Name = styled.h3`
  color: #0b1538;
  font-size: 20px;
  font-weight: 900;
  text-align: center;
  text-transform: uppercase;
  @media screen and (max-width: 1024px) {
    font-size: 18px;
  }
  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
  @media screen and (max-width: 425px) {
    font-size: 14px;
  }
`;

export const Description = styled.div`
  padding: 10px 0;
  color: #697785;
  font-weight: 400;
  text-align: center;
  flex-grow: 1;
  @media screen and (max-width: 1024px) {
    font-size: 16px;
  }
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
  @media screen and (max-width: 425px) {
    font-size: 12px;
  }
`;

export const Link = styled.div`
  color: #2b56f3;
  cursor: pointer;
  text-decoration: underline;
  text-align: center;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const Icon = styled.div`
  display: flex;
  justify-content: center;
  width: 60px;
  @media screen and (max-width: 768px) {
    width: 50px;
  }
`;

// ContactCardContainer,
//   Card,
//   Name,
//   Description,
//   Link,
//   Icon,
