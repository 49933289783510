import { combineReducers } from "redux";
import { userReducer } from "./user/user.reducer";
import { productsReducer } from "./product/product.reducer";
import { cartReducer } from "./cart/cart.reducer";
import { ordersReducer } from "./orders/orders.reducer";
import { USER_ACTION_TYPES } from "./user/user.types";
import storage from "redux-persist/lib/storage";

const appReducer = combineReducers({
  user: userReducer,
  products: productsReducer,
  cart: cartReducer,
  orders: ordersReducer,
});

const rootReducer = (state, action) => {
  if (action.type === USER_ACTION_TYPES.SIGN_OUT_SUCCESS) {
    // when a logout action is dispatched it will reset redux state

    // we keep a reference of the keys we want to maintain
    // other keys will be passed as undefined and this will call
    // reducers with an initial state
    const { products } = state;

    state = { products };
  }

  return appReducer(state, action);
};

export default rootReducer;
