import { takeLatest, all, call, put } from "redux-saga/effects";

import { getProductAndDocuments } from "../../utils/firebase/firebase.utils";
import { fetchProductsFailed, fetchProductsSuccess } from "./product.action";
import { PRODUCTS_ACTION_TYPES } from "./product.type";

export function* fetchProductsAsync() {
  try {
    const productsArray = yield call(getProductAndDocuments, "products");
    yield put(fetchProductsSuccess(productsArray));
  } catch (error) {
    yield put(fetchProductsFailed(error));
  }
}

export function* onFetchProducts() {
  yield takeLatest(
    PRODUCTS_ACTION_TYPES.FETCH_PRODUCTS_START,
    fetchProductsAsync
  );
}

export function* productsSagas() {
  yield all([call(onFetchProducts)]);
}
