import styled from "styled-components";
import { ReactComponent as ContactBg } from "../../assets/contact/contact-bg.svg";
// const subColor = "grey";
const contactTextColor = "grey";
const contactTextSize = "18pxs";
// export const FormInput = styled.

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const HeaderContainer = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  top: 0;
  left: 0;
  right: 0;
`;

export const HeaderBg = styled(ContactBg)`
  position: absolute;
  width: 100%;
  height: auto;
  top: 0;
  left: 0;
  z-index: 2;
`;

export const SectionBg = styled.div`
  /* background-color: #fcf6f6; */
  position: absolute;
  /* top: 0; */
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
`;

// grid-template-areas: "head head"
// "nav nav"
// "info services"
// "footer footer";
// grid-template-rows: 300px 120px 800px 120px;
// grid-template-columns: 1fr 3fr;

export const HeaderContent = styled.div`
  /* top: 8%; */
  padding: 5% 10%;
  z-index: 10;
  position: relative;
  display: grid;
  grid-template: repeat(2/1fr) / 1fr;
  /* align-items: center;
  justify-content: center; */
  grid-template-areas:
    "title "
    "contactInfo";

  @media screen and (max-width: 1024px) {
    padding: 8% 8% 0 8%;
  }
  @media screen and (max-width: 425px) {
    padding: 15% 8% 0 8%;
  }
`;

export const PageTitle = styled.div`
  grid-area: title;
  padding-left: 3%;
  /* margin: auto; */
  /* width: 80%; */
  h1 {
    padding-top: 20px;
    font-size: 70px;
    font-weight: 900;
    // float: inline-end;
  }
  @media screen and (max-width: 1024px) {
    h1 {
      font-size: 38px;
    }
  }
  @media screen and (max-width: 425px) {
    h1 {
      font-size: 28px;
    }
  }
`;

export const ContextStyle = styled.div`
  grid-area: contactInfo;
  /* margin: st; */
  margin-top: 50px;
  /* padding-left: 100px; */
  /* padding-right: 300px; */

  h1 {
    font-size: 30px;
    font-weight: 900;
    line-height: 1.5;
    padding: 10px 0;
    // float: inline-end;
  }
  h2 {
    text-transform: uppercase;
    color: #3e3ee0;
  }
  p {
    font-size: 18px;
    font-weight: 400;
    color: #697785;
    line-height: 1.5;
  }
  span a {
    color: #2b56f3;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  @media screen and (max-width: 1024px) {
    h1 {
      font-size: 25px;
    }
    h2 {
      font-size: 18px;
    }
    p {
      font-size: 16px;
    }
  }
  @media screen and (max-width: 768px) {
    h1 {
      font-size: 20px;
    }
    h2 {
      font-size: 16px;
    }
    p {
      font-size: 14px;
    }
  }
  @media screen and (max-width: 425px) {
    margin-top: 30px;
    h1 {
      font-size: 18px;
    }
    h2 {
      font-size: 14px;
    }
    p {
      font-size: 12px;
    }
  }
`;

export const ContactFormContainer = styled.div`
  width: 100%;
  /* form {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(3/1fr);

    & > div:nth-child(1) {
      grid-column: 1 / span 1;
      grid-row: auto;
    }
    & > div:nth-child(2) {
      grid-column: 2 / span 1;
      grid-row: auto;
    }
    & > div:nth-child(3) {
      grid-column: 3 / span 1;
      grid-row: auto;
    }
    & > div:nth-child(4) {
      grid-column: 1 / span 3;
      grid-row: auto;
    } 
  }*/
`;
export const Textarea = styled.textarea`
  /* grid-area: 2/1/4/4; */
  margin-bottom: 45px;
  border: none;
  font-size: ${contactTextSize};
  color: ${contactTextColor};
  width: 100%;
  height: 300px;
  font-family: "Noto Sans Japanese";
`;

export const FormGroup = styled.div``;

// .headerText
//   margin: auto;
// padding-top: 170px;
// padding-left: 100px;
// padding-right: 300px;
//   grid-area: missioninfo;
//

// FAQS SECTIONs
export const FaqsContainer = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  top: 0;
  left: 0;
  right: 0;
`;

export const FaqsContent = styled.div`
  padding: 0 10% 5% 10%;
  z-index: 10;
  position: relative;
  /* grid-template: repeat(2/1fr) / 1fr; */
  /* align-items: center; */
  /* justify-content: center; */
  /* grid-template-areas:
    "title "
    "contactInfo"; */
`;

// Contact Card Section

export const ContactCardContainer = styled.div`
  padding: 100px 0;
`;
