import { takeLatest, put, all, call } from "redux-saga/effects";

import { USER_ACTION_TYPES } from "./user.types";

import {
  signInSuccess,
  signInFailed,
  signUpSuccess,
  signUpFailed,
  signOutSuccess,
  signOutFailed,
  updateProfileSuccess,
  updateProfileFailed,
  reauthenticationFailed,
  reauthenticationSuccess,
  updatePasswordFailed,
  updatePasswordSuccess,
  updateEmailFailed,
  updateEmailSuccess,
  updateEmailDatabaseSuccess,
  resetPasswordFailed,
  resetPasswordSuccess,
  authSuccess,
} from "./user.action";
import {
  getCurrentUser,
  createUserDocumentFromAuth,
  signInAuthUserWithEmailAndPassword,
  createAuthUserWithEmailAndPassword,
  signOutUser,
  updateUserDocumentFromAuth,
  reauth,
  updateNewPassword,
  updateUserEmail,
  updateUserEmailDatabase,
  sendResetPassword,
  updateProfileDisplayName,
} from "../../utils/firebase/firebase.utils";

export function* getSnapshotFromUserAuth(userAuth, additionalDetails) {
  try {
    const userSnapshot = yield call(
      createUserDocumentFromAuth,
      userAuth,
      additionalDetails
    );

    // console.log(userSnapshot);
    yield put(signInSuccess({ id: userSnapshot.id, ...userSnapshot.data() }));
    // console.log(userSnapshot.data());
  } catch (error) {
    // console.log(error);

    yield put(signInFailed(error));
  }
}
export function* signInWithEmail({ payload: { email, password } }) {
  try {
    const { user } = yield call(
      signInAuthUserWithEmailAndPassword,
      email,
      password
    );
    yield call(getSnapshotFromUserAuth, user);
  } catch (error) {
    yield put(signInFailed(error));
  }
}

export function* isUserAuthenticated() {
  try {
    const userAuth = yield call(getCurrentUser);
    if (!userAuth) return;
    // yield put(authSuccess);
    yield call(getSnapshotFromUserAuth, userAuth);
  } catch (error) {
    yield put(signInFailed(error));
  }
}

export function* signUp({ payload: { email, password, additionalDetails } }) {
  try {
    const { user } = yield call(
      createAuthUserWithEmailAndPassword,
      email,
      password
    );

    yield call(updateProfileDisplayName, additionalDetails.firstname);
    // additionalDetails.firstname

    yield put(signUpSuccess(user, additionalDetails));
  } catch (error) {
    yield put(signUpFailed(error));
  }
}

export function* signOut() {
  try {
    yield call(signOutUser);
    yield put(signOutSuccess());
  } catch (error) {
    yield put(signOutFailed(error));
  }
}

export function* reSignIn({ payload: { email, password } }) {
  try {
    // console.log("start2");
    // console.log(email);
    // console.log(password);
    const credential = yield call(reauth, email, password);
    // console.log(credential);
    yield put(reauthenticationSuccess, credential);
  } catch (error) {
    yield put(reauthenticationFailed(error));
  }
}

// export function* signInWithEmail({ payload: { email, password } }) {
//   try {
//     const { user } = yield call(
//       signInAuthUserWithEmailAndPassword,
//       email,
//       password
//     );
//     yield call(getSnapshotFromUserAuth, user);
//   } catch (error) {
//     yield put(signInFailed(error));
//   }
// }

export function* updateProfile({ payload: { user, additionalDetails } }) {
  try {
    // console.log(user);
    const userSnapshot = yield call(
      updateUserDocumentFromAuth,
      user,
      additionalDetails
    );
    // console.log(userSnapshot.data());
    yield put(
      updateProfileSuccess({ id: userSnapshot.id, ...userSnapshot.data() })
    );
    // const { user } = yield call(
    //   createAuthUserWithEmailAndPassword,
    //   email,
    //   password
    // );

    // yield put(signUpSuccess(user, additionalDetails));
  } catch (error) {
    yield put(updateProfileFailed(error));
  }
}

export function* updatePassword({ payload }) {
  try {
    // console.log("update start 1");
    yield call(updateNewPassword, payload);
    // console.log(newPasswordSnapshot);
    yield put(updatePasswordSuccess(payload));
  } catch (error) {
    yield put(updatePasswordFailed(error));
  }
}

export function* updateEmail({ payload }) {
  try {
    // console.log("update Email Start 1");
    const message = yield call(updateUserEmail, payload);
    // console.log(message);
    yield put(updateEmailSuccess(payload));
  } catch (error) {
    yield put(updateEmailFailed(error));
  }
}

export function* signInAfterSignUp({ payload: { user, additionalDetails } }) {
  yield call(getSnapshotFromUserAuth, user, additionalDetails);
}

export function* updateEmailonDatabase({ payload }) {
  try {
    // console.log("2");
    const userSnapshot = yield call(updateUserEmailDatabase, payload);

    // console.log(userSnapshot);
    // yield put(
    //   updateEmailDatabaseSuccess({
    //     id: userSnapshot.id,
    //     ...userSnapshot.data(),
    //   })
    // );
    yield put(
      updateProfileSuccess({ id: userSnapshot.id, ...userSnapshot.data() })
    );
  } catch (error) {}
}

export function* resetPassword({ payload }) {
  try {
    // console.log("resetpassword start 1");
    const resetSanpshot = yield call(sendResetPassword, payload);

    // console.log(resetSanpshot);
    if (resetSanpshot.success) {
      yield put(resetPasswordSuccess());
    } else {
      yield put(resetPasswordFailed(resetSanpshot.error));
    }

    // yield put(resetPasswordSuccess());
  } catch (error) {
    yield put(resetPasswordFailed(error));
  }
}

export function* onCheckUserSession() {
  yield takeLatest(USER_ACTION_TYPES.CHECK_USER_SESSION, isUserAuthenticated);
}

export function* onEmailSignInStart() {
  yield takeLatest(USER_ACTION_TYPES.EMAIL_SIGN_IN_START, signInWithEmail);
}

export function* onSignUpStart() {
  yield takeLatest(USER_ACTION_TYPES.SIGN_UP_START, signUp);
}

export function* onSignUpSuccess() {
  yield takeLatest(USER_ACTION_TYPES.SIGN_UP_SUCCESS, signInAfterSignUp);
}

export function* onSignOutStart() {
  yield takeLatest(USER_ACTION_TYPES.SIGN_OUT_START, signOut);
}

export function* onUpdateProfileStart() {
  yield takeLatest(USER_ACTION_TYPES.UPDATE_PROFILE_START, updateProfile);
}

export function* onReAuthenticationStart() {
  yield takeLatest(USER_ACTION_TYPES.RE_AUTH_START, reSignIn);
}

export function* onUpdatePasswordStart() {
  yield takeLatest(USER_ACTION_TYPES.UPDATE_PASSWORD_START, updatePassword);
}

export function* onUpdateEmailStart() {
  yield takeLatest(USER_ACTION_TYPES.UPDATE_EMAIL_START, updateEmail);
}

export function* onUpdateEmailSuccess() {
  yield takeLatest(
    USER_ACTION_TYPES.UPDATE_EMAIL_SUCCESS,
    updateEmailonDatabase
  );
}

export function* onResetPasswordStart() {
  yield takeLatest(USER_ACTION_TYPES.RESET_PASSWORD_START, resetPassword);
}

export function* userSagas() {
  yield all([
    call(onCheckUserSession),
    call(onEmailSignInStart),
    call(onSignUpStart),
    call(onSignUpSuccess),
    call(onSignOutStart),
    call(onUpdateProfileStart),
    call(onReAuthenticationStart),
    call(onUpdatePasswordStart),
    call(onUpdateEmailStart),
    call(onUpdateEmailSuccess),
    call(onResetPasswordStart),
  ]);
}
