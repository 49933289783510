import { Outlet } from "react-router-dom";
import { Fragment, useState } from "react";
import {
  NavigationContainer,
  NavLinks,
  NavLink,
  LogoContainer,
  SignInLink,
  SignInNavLink,
  OpenLinksButton,
  NavbarContainer,
  CoaonLogo,
} from "./navigation.styles";

import { selectCurrentUser } from "../../store/user/user.selector";
import MenuIcon from "@mui/icons-material/Menu";

import { useSelector } from "react-redux";
import NavigationTopDrop from "../navigation-top-drop/navigation-top-drop.componet";

// import Button, { BUTTON_TYPE_CLASSES } from "../button/button.component";

const Navigation = () => {
  const currentUser = useSelector(selectCurrentUser);
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Fragment>
      <NavigationTopDrop isOpen={isOpen} toggle={toggle} />
      <NavbarContainer>
        <NavigationContainer>
          <LogoContainer to="/">
            <CoaonLogo />
          </LogoContainer>
          <NavLinks>
            <NavLink to="/about">About</NavLink>
            <NavLink to="/price">Pricing</NavLink>
            <NavLink to="/contact">Contact</NavLink>
          </NavLinks>
          <SignInLink>
            {currentUser ? (
              <SignInNavLink to="/account/">Account</SignInNavLink>
            ) : (
              //   <SignInNavLink as="span" onClick={signOutUser}>
              //   Log out
              // </SignInNavLink>
              <SignInNavLink to="/signin">Sign In</SignInNavLink>
            )}
          </SignInLink>
          <OpenLinksButton onClick={toggle}>
            <span>
              <MenuIcon />
            </span>
          </OpenLinksButton>
        </NavigationContainer>
        <Outlet />
      </NavbarContainer>
    </Fragment>
  );
};
export default Navigation;
