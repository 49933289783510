import { motion } from "framer-motion";
import { Fragment, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import Backdrop from "../backdrop/backdrop.component";
import Button, { BUTTON_TYPE_CLASSES } from "../button/button.component";
import FormInput from "../form-input/form-input.component";
import {
  ButtonsContainer,
  ClickLink,
  ErrorContext,
  SignInContainer,
  SignInNForgot,
} from "../sign-in-form/sign-in-form.styles";
import LoginModal from "./verify-login/verify-login.component";
import "./update-modal.styles.scss";

import UpdateEmailForm from "./update-email-form/update-email-form.component";
import UpdatePasswordForm from "./update-password-form/update-password.component-form";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUser } from "../../store/user/user.selector";
import {
  emailSignInStart,
  reauthenticationStart,
  updateEmailStart,
  updatePasswordStart,
} from "../../store/user/user.action";
import { reauth } from "../../utils/firebase/firebase.utils";
import { useFormik } from "formik";
import { signInSchema } from "../../validations/uploadValidation";
import ErrorOutlinedIcon from "@mui/icons-material/ErrorOutlined";

const UpdateEmailModal = ({ handleClose, updateType }) => {
  const currentUser = useSelector(selectCurrentUser);
  const defaultFormFields = {
    email: currentUser.email,
    password: "",
    newemail: "",
    // currentpassword: "",
    newpassword: "",
    comfirmnewpassword: "",
    credential: "",
  };

  const dispatch = useDispatch();

  const dropIn = {
    hidden: {
      y: "-100vh",
      opacity: 0,
    },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.1,
        type: "spring",
        damping: 25,
        stiffness: 500,
      },
    },
    exit: {
      y: "100vh",
      opacity: 0,
    },
  };

  // const [formFields, setFormFields] = useState(defaultFormFields);
  // const { email, password } = formFields;
  const [page, setPage] = useState(0);
  const [formData, setFormData] = useState(defaultFormFields);
  const [error, setError] = useState("");

  const {
    email,
    password,
    newemail,
    newpassword,
    comfirmnewpassword,
    credential,
  } = formData;

  const FormTitles = [
    "Please verify your account",
    "Update Your Email",
    "Update New Password",
  ];

  const FormSubTitles = [
    "to continue edit your profile",
    "Enter new email into the field",
    "Enter new password & verify new password",
  ];

  // useEffect(() => {
  //   if (currentUser) {
  //     let currentUserformFields = {
  //       email: currentUser.email,
  //       password: "",
  //       newemail: "",
  //       // currentpassword: "",
  //       newpassword: "",
  //       comfirmnewpassword: "",
  //       credential: "",
  //     };
  //     setFormData(currentUserformFields);
  //   }
  // }, [currentUser]);

  const PageDisplay = () => {
    switch (page) {
      case 0:
        return <LoginModal formData={formData} setFormData={setFormData} />;
      case 1:
        if (updateType === "email") {
          return (
            <UpdateEmailForm formData={formData} setFormData={setFormData} />
          );
        } else {
          return (
            <UpdatePasswordForm formData={formData} setFormData={setFormData} />
          );
        }

      default:
    }
  };

  const resetFormData = () => {
    setFormData(defaultFormFields);
  };

  const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  const isPasswordStrong = (password) => {
    return /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,20})/.test(
      password
    );
  };

  const handleClick = async () => {
    if (page === 1) {
      if (credential && updateType === "email") {
        if (!newemail) {
          setError("New email is required");
          return;
        }
        if (!isValidEmail(newemail)) {
          setError("Email is invalid");
          return;
        }
        // console.log(newemail);
        try {
          dispatch(updateEmailStart(newemail));
          await resetFormData();
          alert("Your Email has been updated");
          setError("");
          handleClose();
        } catch (error) {
          setError(error);
        }
      } else {
        if (!newpassword || !comfirmnewpassword) {
          setError("New Password and Comfirm New Password are required");
          return;
        }
        if (newpassword === comfirmnewpassword) {
          if (!isPasswordStrong(newpassword)) {
            setError(
              " - At least 8 characters \n - At least one (1) uppercase \n - At least one (1) lowercase \n - At least one (1) Number\n - At least (1) special case character [!@#$%^&*] \n - Maximum 20 characters"
            );
            return;
          }
          try {
            dispatch(updatePasswordStart(newpassword));
            await resetFormData();
            setError("");
            alert("Your Password has been updated");
            handleClose();
          } catch (error) {
            setError(error);
          }
        } else {
          setError("Password is not the same as comfirm password");
        }
        // console.log(newpassword);
        // console.log(comfirmnewpassword);
      }
      // alert("FORM SUBMITTED");
      // console.log(formData);
    } else if (page === 0) {
      if (!password) {
        setError("Password is required");
        return;
      }

      // console.log(email, password);
      // dispatch(emailSignInStart(email, password));
      try {
        const credentialSnapshot = await reauth(email, password);
        await setFormData({ ...formData, ["credential"]: credentialSnapshot });
        setError("");
        // dispatch(reauthenticationStart(email, password));
        setPage((currPage) => currPage + 1);
      } catch (error) {
        switch (error.code) {
          case "auth/wrong-password":
            setError("The password is invalid");

            break;

          case "auth/too-many-requests":
            setError(
              "Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later"
            );

            break;

          default:
            setError(
              "Your Password is incorrect or this account doesn't exist, Please try again!!"
            );

            break;
        }
      }
    } else {
      setPage((currPage) => currPage + 1);
    }
  };

  return (
    <Backdrop onClick={handleClose}>
      <motion.div
        onClick={(e) => e.stopPropagation()}
        className="modalUpdate modalCard"
        variants={dropIn}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        <div className="form-container">
          <div className="header">
            <h1>
              {updateType === "password" && page === 1
                ? FormTitles[2]
                : FormTitles[page]}
            </h1>
            <h2>
              {updateType === "password" && page === 1
                ? FormSubTitles[2]
                : FormSubTitles[page]}
            </h2>
          </div>
          <div className="body">{PageDisplay()}</div>
          {error && (
            <ErrorContext>
              <ErrorOutlinedIcon />
              <div>{error}</div>
            </ErrorContext>
          )}
          <div className="footer">
            <div hidden={page === 0}>
              <Button
                onClick={() => {
                  setError("");
                  setPage((currPage) => currPage - 1);
                }}
              >
                Prev
              </Button>
            </div>
            <Button onClick={handleClick}>
              {page === 1 ? "Update" : "Next"}
            </Button>
          </div>
        </div>
      </motion.div>
    </Backdrop>
  );
};

export default UpdateEmailModal;
