import { useState } from "react";
import Dropdown from "../../components/dropdown/dropdown.component";
import Option from "../../components/dropdown/option.component";
import { useDispatch } from "react-redux";
import { Fragment } from "react";
import FormInput from "../../components/form-input/form-input.component";
import {
  HeaderContainer,
  HeaderContent,
  PageTitle,
  ContactFormContainer,
  ButtonsContainer,
  HeaderBg,
  SectionBg,
  ContextStyle,
  FaqsContainer,
  FaqsContent,
  FormGroup,
  Textarea,
  ContactCardContainer,
} from "./contact.styles";
import Footer from "../../components/footer/footer.component";
import Button from "../../components/button/button.component";
import Faqs from "../../components/faqs/faqs.component";
import ContactCard from "../../components/contact-card/contact-card.component";
const defaultFormFields = {
  name: "",
  email: "",
  subject: "Select Subject",
  message: "",
};

const Contact = () => {
  const [formFields, setFormFields] = useState(defaultFormFields);
  const { name, email, subject, message } = formFields;
  // const dispatch = useDispatch();

  const resetFormFields = () => {
    setFormFields(defaultFormFields);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // if (password !== confirmPassword) {
    //   alert("passwords do not match");
    //   return;
    // }

    try {
      // dispatch(signUpStart(email, password, displayName));
      console.log("subject: " + subject);
      console.log("name: " + name);
      console.log("email: " + email);
      console.log("message: " + message);
      resetFormFields();
    } catch (error) {
      // if (error.code === "auth/email-already-in-use") {
      //   alert("Cannot create user, email already in use");
      // } else {
      //   console.log("user creation encountered an error", error);
      // }
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormFields({ ...formFields, [name]: value });
  };
  return (
    <Fragment>
      <HeaderContainer className="intro">
        <HeaderContent>
          <PageTitle>
            <h1>Contact Us</h1>
          </PageTitle>
          <ContextStyle>
            <h2>Write a message</h2>
            <h1>If you have any questions, send us a message</h1>
            <ContactFormContainer>
              <ContactCard />
            </ContactFormContainer>
          </ContextStyle>
        </HeaderContent>
        <HeaderBg />
        <SectionBg />
      </HeaderContainer>

      <FaqsContainer>
        <FaqsContent>
          <ContextStyle>
            <h2>FAQs</h2>
            <h1>Have any questions ?</h1>
          </ContextStyle>
          <Faqs />
        </FaqsContent>
      </FaqsContainer>

      <Footer />
    </Fragment>
  );
};
export default Contact;

// <ContactCardContainer>
// <ContactCard />
// </ContactCardContainer>

// <form onSubmit={handleSubmit}>
// <FormGroup>
//   <FormInput
//     label="Name"
//     type="text"
//     required
//     onChange={handleChange}
//     onSubmit={handleSubmit}
//     name="name"
//     value={name}
//   />
// </FormGroup>
// <FormGroup>
//   <FormInput
//     label="Email"
//     type="email"
//     required
//     onChange={handleChange}
//     name="email"
//     value={email}
//   />
// </FormGroup>
// <FormGroup>
//   <Dropdown
//     label="Subject"
//     onChange={handleChange}
//     name="subject"
//   >
//     <Option defaultValue value={subject} />
//     <Option value="Writing Packages" />
//     <Option value="Order" />
//     <Option value="Cancel an order" />
//     <Option value="Account issue" />
//     <Option value="Update information" />
//     <Option value="Refund" />
//     <Option value="Others" />
//   </Dropdown>
// </FormGroup>
// <FormGroup>
//   <Textarea
//     label="message"
//     type="text"
//     required
//     onChange={handleChange}
//     cols="30"
//     rows="7"
//     name="message"
//     placeholder=""
//     value={message}
//   />
// </FormGroup>
// <ButtonsContainer>
//   <Button type="submit">Send Message</Button>
// </ButtonsContainer>
// </form>
