import styled from "styled-components";
import { Link } from "react-router-dom";

export const SignInContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 500px;

  h1 {
    font-size: 38px;
    font-weight: 900;
    text-transform: uppercase;
    margin: 0;
    /* line-height: 1.5; */
    /* padding: 10px 0; */
    // float: inline-end;
  }
  h2 {
    text-transform: uppercase;
    color: #3e3ee0;
    margin: 10px 0;
    font-size: 18px;
  }

  @media screen and (max-width: 1024px) {
    width: 400px;
    h1 {
      font-size: 25px;
    }
    h2 {
      font-size: 18px;
    }
  }
  @media screen and (max-width: 768px) {
    width: 300px;
    h1 {
      font-size: 20px;
    }
    h2 {
      font-size: 16px;
    }
  }
  @media screen and (max-width: 425px) {
    width: 250px;
    h1 {
      font-size: 18px;
    }
    h2 {
      font-size: 14px;
    }
  }
`;

// export const SignInCard = styled.div``;

export const SignInNForgot = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const NavLink = styled(Link)`
  padding: 10px 15px;
  cursor: pointer;
  color: #202842;
  font-weight: 400;
  &:hover {
    text-decoration: underline;
    color: #2b56f3;
  }

  /* justify-content: center;
  align-items: center; */
`;
export const ClickLink = styled.a`
  /* padding: 10px 15px; */
  cursor: pointer;
  color: #202842;
  font-weight: 400;
  &:hover {
    text-decoration: underline;
    color: #2b56f3;
  }
  @media screen and (max-width: 1024px) {
    font-size: 16px;
  }
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
  @media screen and (max-width: 425px) {
    font-size: 12px;
  }
`;

export const ButtonsContainer = styled.div`
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  /* @media screen and (max-width: 425px) {
    justify-content: center;
  } */
`;

export const ErrorContext = styled.div`
  display: flex;
  align-items: center;

  padding: 20px 0;
  color: #ea5e5d;

  & div {
    padding: 0 10px;
    font-size: 14px;
    /* p { */
    white-space: pre-line;
    /* } */

    @media screen and (max-width: 768px) {
      /* p { */
      font-size: 12px;
      /* } */
    }
  }

  /* background-color: #ea5e5d; */
  /* border: 2px solid #ea5e5d; */
`;
