import { createAction } from "../../utils/reducer/reducer.utils";
import { ORDER_ACTION_TYPES } from "./orders.types";

export const saveOrderHistory = (
  collectionKey,
  objectsToAdd,
  orderId,
  userId,
  firstname,
  lastname,
  email,
  phone,
  amount,
  cartItems
) =>
  createAction(ORDER_ACTION_TYPES.SAVE_ORDER_HISTORY_START, {
    collectionKey,
    objectsToAdd,
    orderId,
    userId,
    firstname,
    lastname,
    email,
    phone,
    amount,
    cartItems,
  });

export const saveOrderHistoryFailed = (error) =>
  createAction(ORDER_ACTION_TYPES.SAVE_ORDER_HISTORY_FAILED, error);

export const getUserOrderHistory = (uid) =>
  createAction(ORDER_ACTION_TYPES.GET_USER_ORDER_HISTORY_START, uid);

export const getUserOrderHistoryFailed = (error) =>
  createAction(ORDER_ACTION_TYPES.GET_USER_ORDER_HISTORY_FAILED, error);

export const setUserOrderHistory = (history) =>
  createAction(ORDER_ACTION_TYPES.SET_USER_ORDER_HISTORY, history);

export const updateStatusOrderStart = (orderId, status, uid) =>
  createAction(ORDER_ACTION_TYPES.UPDATE_STATUS_ORDER_START, {
    orderId,
    status,
    uid,
  });

export const updateStatusOrderSuccess = (uid) =>
  createAction(ORDER_ACTION_TYPES.UPDATE_STATUS_ORDER_SUCCESS, uid);

export const updateStatusOrderFailed = (error) =>
  createAction(ORDER_ACTION_TYPES.UPDATE_STATUS_ORDER_FAILED, error);

export const clearErrorOrder = () =>
  createAction(ORDER_ACTION_TYPES.CLEAR_ERROR_ORDER);

// export const updateStatusOrderSuccess = (status) =>
//   createAction(ORDER_ACTION_TYPES.UPDATE_STATUS_ORDER_SUCCESS, status);
