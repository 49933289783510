import {
  TermsContainer,
  Wrapper,
  PageTitle,
  HeaderBG,
  Content,
} from "./terms.styled";
import Footer from "../../components/footer/footer.component";
export const Terms = () => {
  return (
    <TermsContainer>
      <Wrapper>
        <PageTitle>
          <h1>Terms of Services</h1>
        </PageTitle>
        <HeaderBG />
      </Wrapper>
      <Content>
        <h2>Last updated: June 29, 2022 </h2>

        <p>
          Please read these Terms of Services carefully before using the
          <span>
            <a> http://www.coaon.net </a>
          </span>
          website operated by COAON LLC.
        </p>
        <p>
          Your access to and use of the Service is conditioned on your
          acceptance of and compliance with these Terms. These Terms apply to
          all visitors, users and others who access or use the Service.
        </p>

        <p>
          <strong>
            By accessing or using the Service, you agree to be bound by these
            Terms. If you disagree with any part of the terms then you may not
            access the Service.
          </strong>
        </p>

        <h1>Purchases</h1>
        <p>
          If you wish to purchase any product or service made available through
          our Services and Site, you will be asked to supply certain information
          relevant to your Purchase including, without limitation, your email
          address, phone number, first and last names,physical address.
        </p>

        <p>
          Your purchase of any of our Products/Software and or Services are only
          for noncommercial purposes. Your access to or use of the Services
          and/or Products/Software shall be contingent upon your payment of all
          applicable fees; unless you are an Authorized User, your access to or
          use of the Services and/or Products/Software shall be contingent upon
          your Subscriber’s payment of the Fees.
        </p>
        <p>
          Upon registering for Services, unless you are a registered user, you
          will be required to designate a valid payment method. You hereby
          authorize COAON to charge to your designated account all Fees relating
          to the Services you select, and you agree to pay all such Fees in
          accordance with the applicable payment method terms and conditions.
        </p>

        <h1>License</h1>
        <p>
          In acceptance of “Terms of Services” and your payment to all
          applicable fees, COAON grants you a limited, non-transferable,
          non-exclusive, non-sublicensable access to use the Site, the Services
          and the Software solely for your own personal purposes.
        </p>
        <p>
          You can upload one document per time and the document can not exceed 5
          MB. COAON currently only accepts doc, docx, pdf, txt document formats.
        </p>

        <p>
          <strong>Additionally, you can not:</strong>
        </p>
        <p>
          <ul>
            <li>
              Copy, modify, retrieve any information/works from the Site,
              Service, Software, Documents
            </li>
            <li>
              Store, download, distribute, transmit, publish, disseminate,
              export/re-export COAON’s IP
            </li>
            <li>
              Transfer your right to any other third party, access and use the
              Service/Software for any third party
            </li>
            <li>
              Transmit, share any content containing intellectual property or
              proprietary rights from COAON to any party
            </li>
            <li>
              Log on to Server/Account that you are not authorized, and or
              access content not intent for you
            </li>
            <li>
              Test, scan, probe any of COAON’s Site, Services and/or Software,
              or any associated system or network
            </li>
            <li>
              Implement any activities to access Site/Service/Software (such as
              mocking your IP address or using a proxy IP address) if you have
              been blocked by COAON{" "}
            </li>
            <li>
              Limit, Interfere, or attempt to interfere with service to any
              user, host or network
            </li>
            <li>
              Use any of Service/Software/IP to build a competitive product,
              service or solution
            </li>
            <li>
              Automate access to the Site/Services/Software using APIs, bots,
              scrapers or other similar devices
            </li>
          </ul>
        </p>

        <h1>Content</h1>
        <p>
          Our Service allows you to post, link, store, share and otherwise make
          available certain information, text, graphics, videos, or other
          material ("Content") for noncommercial purposes. You are responsible
          for the accuracy and copyright issues.
        </p>

        <p>
          You are solely responsible for all text, documents or other content or
          information uploaded, entered or otherwise transmitted by you in
          connection with your use of the Services and/or Software (“User
          Content”). User Content includes, among other things, any mistakes
          contained in the content or information transmitted by you. You
          warrant that you own and have a valid and enforceable license to use
          User Content and no User Content misappropriates and or violates the
          copyrights and or intellectual property rights.
        </p>
        <p>
          By uploading your document or entering any information to our website,
          you give COAON a permission to copy, store and use your User Content
          in connection with the provision of the Software and the Services and
          to improve the algorithms underlying the Software and the Services.{" "}
        </p>

        <h1>Links To Other Websites</h1>
        <p>
          Our Service may contain links to third-party web sites or services
          that are not owned or controlled by COAON. Third party websites may be
          provided and they are for your convenience only.
        </p>
        <p>
          COAON has no control over, and assumes no responsibility for, the
          content, privacy policies, or practices of any third party web sites
          or services. You further acknowledge and agree that COAON shall not be
          responsible or liable, directly or indirectly, for any damage or loss
          caused or alleged to be caused by or in connection with the use of or
          reliance on any such content, goods or services available on or
          through any such websites or services.
        </p>

        <h1>Cancellation and Refund Policies</h1>
        <p>
          Your request is very important to us. COAON understands that there
          might be an unexpected situation.
        </p>
        <p>
          Cancellation can be made through our system at any time. If you do not
          have access to the computer, you can reach our company through our
          phone/email at business hours (9am-5pm EST on Monday - Friday, except
          holidays).
        </p>
        <p>
          If your cancellation is made before we have started working on orders
          for your documents (it means that the status of your order has not
          been changed to “in progress”), you can request for a refund and your
          refund will be made available to the credit card you stored with us.
        </p>
        <p>
          Once you place an order for EB-2 NIW guidelines, you will have
          immediate access to the secret information. As you have accessed
          information, your request for refund cannot be fulfilled.
        </p>
        <p>
          If you have canceled your requests but then wish to change your mind
          to keep using the service, you will need to email us at
          <span>
            <a> support@coaon.net </a>
          </span>
          about your changes to request cancellation action.
        </p>

        <h1>Termination</h1>
        <p>
          If you are a registered user, COAON may deny and or terminate your
          access to all or any part of our Service if you engage in any
          activities that violate the COAON’s rights with or without advance
          notice. Without limitation, COAON can terminate your account, and/or
          deny your access to our Software/Services if you violate the
          conditions stated in “Terms of Services”.
        </p>

        <h1>Access To Services</h1>
        <p>
          You are responsible for obtaining, using, and maintaining any devices
          or services needed to access and use our Site/Software/Service
          including, without limitation, modems, hardware, software, internet
          service and telecommunications capacity. You are solely responsible
          for ensuring that such equipment and ancillary services are compatible
          with the Services and Software.
        </p>

        <h1>Changes</h1>
        <p>
          COAON reserves the right, at our sole discretion, to modify or replace
          information in “Terms of Services” at any time. If a revision is
          material we will try to provide at least 10 business day’s notice
          prior to any new terms taking effect by email to your most recently
          provided email address, by posting the updated or revised “Terms and
          Conditions” on the Site, or by any other manner.
        </p>
        <p>
          Your use of the Site/Service/Software following any such update or
          revision implies that you agree to the updated and or revised
          conditions. It is your responsibility to review the “Terms and
          Conditions” periodically.
        </p>

        <h1>Consent To Receive Email</h1>
        <p>
          Your registration to use the Site/Services/Software constitutes your
          consent to receive email communications from COAON, including messages
          regarding customer service issues and other matters. You may opt out
          to email correspondence, other than technical notifications and email
          regarding issues related to your account and your use of the Site and
          Services, at any time by following the link included in the email
          messages.
        </p>
        <p>
          We only send marketing communications to users with your prior
          consent, and you may withdraw your consent at any time by clicking the
          “unsubscribe” link found within emails and changing your contact
          preferences. Please note you will continue to receive transactional
          messages related to our Services, even if you unsubscribe from
          marketing emails.
        </p>

        <h1>Data Collection And Privacy</h1>
        <p>
          COAON does not collect personally identifiable information from you
          except to the extent you have explicitly given such information to us.
          COAON’s information practices are further described in its “Privacy
          Policy”. “Privacy Policy” is an integral part of this Agreement and is
          expressly incorporated by reference, and by entering into this
          Agreement you agree to (i) all of the terms of the Privacy Policy, and
          (ii) COAON’s use of data as described in the Privacy Policy is not an
          actionable breach of your privacy or publicity rights.
        </p>

        <p>
          COAON may update or revise the “Privacy Policy” and COAON will notify
          you either by email to your most recently provided email address, by
          posting the updated or revised “Privacy Policy” on the Site, or by any
          other manner chosen by COAON in its commercially reasonable
          discretion. Your use of the Site/Service/Software following any such
          update or revision implies that you agree to the updated and or
          revised conditions. It is your responsibility to review the “Privacy
          Policy” periodically.
        </p>

        <h1>Ownership</h1>
        <p>
          All intellectual property rights in and to the User Content are and
          shall remain your property, and COAON shall acquire no right of
          ownership with respect to your User Content.
        </p>
        <p>
          All intellectual property rights in and to the Software/ Site/
          Services and other IP are and shall remain the sole property of COAON,
          and you shall acquire no right of ownership or use with respect to any
          Software or other COAON’s IP except as specified in this “Terms of
          Service”.
        </p>
        <p>
          You may provide suggestions, comments, recommendations, improvements,
          solutions, bug fixes, features, concepts, techniques, ideas, know-how
          and/or any feedback regarding the Services/Software/Site and or any
          related technologies (“Feedback”). Any and all Feedback is and shall
          be given entirely voluntarily. COAON shall be freely entitled to
          reproduce, prepare derivative works, disclose to third parties,
          display and perform (publicly or otherwise), sell, lease, license,
          distribute and otherwise use and exploit any and all such Feedback as
          it deems appropriate, at its sole discretion, without obligation or
          liability of any kind to you, or any other person or entity.
        </p>

        <h1>Indemnity</h1>
        <p>
          You shall indemnify, release and hold harmless to COAON and or its
          parents, subsidiaries, affiliates, licensors and suppliers, and each
          of their respective officers, directors, employees and agents, from
          and against any loss, liability (including settlements, judgments,
          fines and penalties) and costs (including reasonable attorney fees,
          court costs and other litigation expenses) relating to any claim or
          demand made by any third party due to or arising out of your access to
          the Site, use of the Services or Software, violation of this “Terms
          and Conditions”, or infringement of any intellectual property or other
          right of any person or entity.
        </p>

        <h1>Warranty Disclaimers</h1>
        <p>
          To the maximum extent permitted by applicable law, COAON expressly
          disclaims any and all all warranties and conditions, express or
          implied, regarding the Site/Service/Software, including but not
          limited to any implied warranties or conditions/quality of
          merchantability, fitness for a particular purpose, title,
          noninfringement, satisfactory quality or arising from a course of
          dealing, law, usage, trade practice, security, reliability, timeless
          and performance. You are aware and agree that your usage of the
          Site/Service/Software is at your own risk and that the
          Site/Service/Software will be provided on an as available, with all
          fault basis, without warranties of any kind, either express or
          implied. Without limitation of the foregoing, COAON does not warrant
          the operation of the Site/Service/Software will meet your requirements
          or will be uninterrupted or error-free.
        </p>

        <h1>Limitation Of Liability</h1>
        <p>
          In no event shall COAON be liable with respect to the
          Site/Service/Software for any amount in the aggregate in excess of the
          fees you have actually paid during the 12 month period before the
          event(s) giving rise to such liability; any lost profits, lost,
          damaged user content/other data, failure to meet any duty, including
          without limitation reasonable care; any indirect, incidental, special
          or consequential damages of any kind whatsoever.
        </p>
        <p>
          You agree that this limitation of liability represents a reasonable
          allocation of risk and is a fundamental element of the basis of the
          bargain between you and COAON. You understand that the
          Site/Service/Software will not be provided without such limitations.
        </p>
        <p>
          Certain states do not allow the limitation of certain damages, so
          some/all of this limitation of liability may or may not apply to you.
        </p>

        <h1>Contact Us</h1>
        <p>If you have any questions about these Terms, please contact us.</p>
      </Content>

      <Footer />
    </TermsContainer>
  );
};
export default Terms;
