import styled from "styled-components";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";

export const ButtonsContainer = styled.div`
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
`;

export const ForgetContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 500px;

  & h1 {
    font-size: 38px;
    font-weight: 900;
    text-transform: uppercase;
    margin: 0;
    /* line-height: 1.5; */
    /* padding: 10px 0; */
    // float: inline-end;
  }

  @media screen and (max-width: 1024px) {
    width: 400px;
    h1 {
      font-size: 25px;
    }
  }
  @media screen and (max-width: 768px) {
    width: 300px;
    h1 {
      font-size: 20px;
    }
  }
  @media screen and (max-width: 425px) {
    width: 250px;

    h1 {
      font-size: 18px;
    }
  }
`;

export const SubHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const SubHeaderText = styled.h2`
  text-transform: uppercase;
  color: #3e3ee0;
  margin: 10px 0;
  font-size: 18px;
  @media screen and (max-width: 1024px) {
    font-size: 16px;
  }
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
  @media screen and (max-width: 425px) {
    font-size: 12px;
  }
`;

export const MailIcon = styled(MarkEmailReadIcon)`
  color: #3e3ee0;
  margin-right: 10px;
`;

export const Header = styled.h3`
  font-size: 25px;
  font-weight: 900;
  /* text-transform: uppercase; */
  margin: 0;
  /* line-height: 1.5; */
  /* padding: 10px 0; */
  // float: inline-end;
  @media screen and (max-width: 1024px) {
    font-size: 20px;
  }
  @media screen and (max-width: 768px) {
    font-size: 18px;
  }
  @media screen and (max-width: 425px) {
    font-size: 16px;
  }
  @media screen and (max-width: 375px) {
    font-size: 14px;
  }
`;

export const EmailText = styled.h3`
  font-size: 30px;
  font-weight: 900;
  text-align: center;
  /* text-transform: uppercase; */
  color: #2b56f3;
  /* margin: 0; */
  @media screen and (max-width: 1024px) {
    font-size: 25px;
  }
  @media screen and (max-width: 768px) {
    font-size: 20px;
  }
  @media screen and (max-width: 425px) {
    font-size: 18px;
  }
`;

export const ShortNote = styled.p`
  font-size: 18px;

  @media screen and (max-width: 1024px) {
    font-size: 16px;
  }
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
  @media screen and (max-width: 425px) {
    font-size: 12px;
  }
`;

export const SpanLink = styled.span`
  cursor: pointer;
  color: #2b56f3;
  font-weight: 400;
  &:hover {
    text-decoration: underline;
    color: #2b56f3;
  }
`;
export const BackLink = styled.div`
  cursor: pointer;
  color: #2b56f3;
  font-weight: 400;
  &:hover {
    text-decoration: underline;
    color: #2b56f3;
  }
`;
