import styled from "styled-components";

export const BaseProduct = styled.div`
  padding: 40px 0 30px 0;
  background-color: white;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  border: none;
  position: relative;
  box-shadow: 0px 24px 44px rgba(0, 0, 0, 0.08);
`;

export const PopularProduct = styled.div`
  /* min-width: 165px; */
  /* width: auto;
  height: 50px; */
  /* padding: 0 35px 0 35px; */
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;

  background-color: white;
  /* height: 600px; */
  position: relative;
  /* color: white; */
  padding-bottom: 30px;
  border: 3px solid #2b56f3;
  box-shadow: 0px 24px 44px rgba(0, 0, 0, 0.08);
`;
export const ProductContainer = styled.div`
  padding-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
`;

export const PopularTag = styled.div`
  color: white;
  background-color: #2b56f3;
  padding: 10px;
  text-align: center;
`;

export const Name = styled.div`
  color: #0b1538;
  font-weight: 900;
  font-size: 18px;
  text-align: center;
  @media screen and (max-width: 1024px) {
    font-size: 16px;
  }
`;

export const SubTitle = styled.div`
  color: #697785;
  text-align: center;
  line-height: 1.5;

  font-weight: 300;
  font-size: 14px;

  @media screen and (max-width: 1024px) {
    font-size: 12px;
    padding: 10px;
  }
`;

export const PriceTag = styled.div`
  color: #0b1538;
  font-size: 50px;
  font-weight: 900;
  text-align: center;
  @media screen and (max-width: 1024px) {
    font-size: 30px;
  }
`;

export const PriceSubTitle = styled.div`
  color: #697785;
  font-size: 12px;
  text-transform: uppercase;
  padding: 20px 0;
  font-weight: 900;
  text-align: center;
  @media screen and (max-width: 1024px) {
    font-size: 12px;
  }
`;

export const ListGroup = styled.ul`
  list-style: none;
  margin-bottom: 30px;
`;

export const List = styled.li`
  font-size: 14px;
  &::before {
    content: "•";
    font-size: 16px;
    color: #2b56f3;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    /* padding: 0 5px; */
    line-height: 2;
    margin-left: -1.2em;
  }
  @media screen and (max-width: 1024px) {
    font-size: 12px;
    &::before {
      font-size: 14px;
    }
  }
`;

export const ButtonContainer = styled.div`
  /* position: absolute; */
  margin-top: auto;
  display: flex;
  justify-content: center;

  /* left: 50%;
  transform: translate(-50%, -50%); */
  /* bottom: 5%;
  left: 20%; */
`;
