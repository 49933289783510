import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/footer/footer.component";

import {
  HeaderContainer,
  HeaderContent,
  PageTitle,
  ContactFormContainer,
  ButtonsContainer,
  HeaderBg,
  SectionBg,
  ContextStyle,
  FaqsContainer,
  FaqsContent,
  FormGroup,
  Textarea,
  ContactCardContainer,
} from "./faqs.styles";

const FAQS = () => {
  const navigate = useNavigate();
  return (
    <Fragment>
      <HeaderContainer className="intro">
        <HeaderContent>
          <PageTitle>
            <h1>FAQs</h1>
          </PageTitle>
          <ContextStyle>
            <h2>OUR SERVICE</h2>
            <h1>1. Who is COAON?</h1>
            <p>
              We provide job application package support for those who strive
              for high-quality job applications. We have the innovative working
              process to be aligned to specific career paths to help our clients
              obtain more opportunities in their dream jobs. COAON integrates
              multiple HR obligations into advanced technology features to offer
              our clients with the most up-to-date responses to current
              recruitment processes at your recruiters’ organizations.
            </p>

            <h1>2. What does COAON offer?</h1>
            <p>
              We offer 5 packages to improve your job applications and enhance
              your understanding in the recruitment process. Our 5 packages for
              job application improvement will help you fix any mistakes in your
              job applications to pass through the ATS screening process and
              catch recruiters’ attention.
            </p>
            <p>
              Each package will focus on specific document to strengthen your
              qualifications correspondingly.
            </p>

            <h1>3. Do I have to pay for the service?</h1>
            <p>
              To take advantage of full support of HR techniques with advanced
              technology from COAON, we recommend you to consider our available
              and affordable packages for job application improvement.
            </p>

            <h1>4. How long does it take to have my document finished?</h1>
            <p>
              We recommend you wait for 1-3 business days to have your document
              available. You will receive an email notification when it is ready
              to download. Just log into your dashboard and you will see your
              document has been there to download already.
            </p>

            <h1>5. Can I be offered one-one supporting services?</h1>
            <p>
              Sure, when you request service with document improvement, your
              document will be surely worked on by one of COAON’s members. This
              member will get in touch with you if there is any concern on your
              document to guarantee the highest quality for yours.
            </p>
            <p>
              If you prefer to talk with one of our members about your documents
              or any concern on your job application, you can send us an email
              at{" "}
              <span>
                <a href="mailto:info@coaon.net">info@coaon.net</a>
              </span>{" "}
              to book the meeting with us.
            </p>

            <h1>
              6. How can I use the supporting services if I am currently not
              available in the area?
            </h1>
            <p>
              We offer online service so you can access our website any time. If
              you have any concerns or questions, please contact us through
              phone or email stated on the website during business hours.
            </p>
          </ContextStyle>
        </HeaderContent>
        <HeaderBg />
        <SectionBg />
      </HeaderContainer>

      <FaqsContainer>
        <FaqsContent>
          <ContextStyle>
            <h2>TECHNICAL ISSUES</h2>
            <h1>1. Do I need an account to use the services? </h1>
            <p>
              Yes. You need to create an account with ID and password to request
              and use any of our service packages. You can keep track of your
              activities as well as our progress on your request through your
              account.
            </p>

            <h1>2. What should I do if I forget my password? </h1>
            <p>
              Just click onto the link{" "}
              <span>
                <a onClick={() => navigate("/forget")}>Forget Your Password</a>
              </span>{" "}
              and type in the email you register your account with us, then hit
              the Reset Password button.
            </p>
            <p>
              You will receive an email with instructions on how to achieve your
              password to access your account.
            </p>

            <h1>3. How can I update my account with another email? </h1>
            <p>
              You can simply log in to your dashboard, go to profile tab to
              enter your new email, and save this change.
            </p>

            <h1>4. Can I delete my account? </h1>
            <p>
              If you wish to remove your account from our database, simply send
              us an email to{" "}
              <span>
                <a href="mailto:support@coaon.net">support@coaon.net</a>
              </span>
            </p>
          </ContextStyle>
        </FaqsContent>
      </FaqsContainer>
      <FaqsContainer>
        <FaqsContent>
          <ContextStyle>
            <h2>ORDER & REFUND</h2>
            <h1>1. How to place an order? </h1>
            <p>
              After logging in, you can go to the Price page and click on the
              package you would like to make a request. We offer 5 packages of
              document support for your job applications:
              <ul>
                <li>Resume/CV Writing</li>
                <li>Resume Checking</li>
                <li> Cover Letter Writing</li>
                <li> Online Portfolio Content Improvement</li>
                <li> Resume + Cover Letter Writing</li>
              </ul>
            </p>

            <h1>2. Can I cancel my request? </h1>
            <p>
              You can cancel your service at any time providing COAON has not
              started processing your request yet.
            </p>
            <p>
              You can log into your account and choose the service package you
              would like to cancel or reach us through email/phone number stated
              on our Website during business hours.{" "}
            </p>

            <h1>3. Can I get a refund if I cancel my request?</h1>
            <p>
              If you cancel your request before COAON starts processing yours,
              you can expect to receive a refund to the card stored in your
              profile within 5-10 business days.
            </p>
            <p>
              *** You need to provide the correct Order ID, COAON will not
              provide any assistance on canceling or refunding requests without
              corresponding Order ID
            </p>
          </ContextStyle>
        </FaqsContent>
      </FaqsContainer>

      <Footer />
    </Fragment>
  );
};
export default FAQS;
