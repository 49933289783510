import styled from "styled-components";
import { Link } from "react-router-dom";

export const SignUpContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 800px;

  h1 {
    font-size: 38px;
    font-weight: 900;
    /* text-transform: uppercase; */
    margin: 0;
    /* line-height: 1.5; */
    /* padding: 10px 0; */
    // float: inline-end;
  }
  h2 {
    text-transform: uppercase;
    color: #3e3ee0;
    margin: 10px 0;
    font-size: 18px;
  }

  p {
    color: #697785;
    font-size: 18px;
  }

  @media screen and (max-width: 1024px) {
    width: 600px;
    h1 {
      font-size: 25px;
    }
    h2 {
      font-size: 18px;
    }
    p {
      font-size: 16px;
    }
  }
  @media screen and (max-width: 768px) {
    width: 500px;
    h1 {
      font-size: 20px;
    }
    h2 {
      font-size: 16px;
    }
    p {
      font-size: 14px;
    }
  }
  @media screen and (max-width: 425px) {
    width: 250px;
    h1 {
      font-size: 18px;
    }
    h2 {
      font-size: 14px;
    }
    p {
      font-size: 12px;
    }
  }
`;

export const FormGroup = styled.div``;
export const GroupInput = styled.div`
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(2/1fr);

  & > div:nth-child(1) {
    grid-column: 1 / span 1;
  }
  & > div:nth-child(2) {
    grid-column: 2 / span 1;
  }
  & > div:nth-child(3) {
    grid-column: 1 / span 1;
  }
  & > div:nth-child(4) {
    grid-column: 2 / span 1;
  }
  @media screen and (max-width: 425px) {
    grid-template-columns: 1fr;
    & > div:nth-child(1) {
      grid-column: 1 / span 1;
    }
    & > div:nth-child(2) {
      grid-column: 1 / span 1;
    }
    & > div:nth-child(3) {
      grid-column: 1 / span 1;
    }
    & > div:nth-child(4) {
      grid-column: 1 / span 1;
    }
  }
`;

export const GroupName = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(3/1fr);
  & > div:nth-child(1) {
    grid-column: 1 / span 1;
    grid-row: auto;
  }
  & > div:nth-child(2) {
    grid-column: 2 / span 1;
    grid-row: auto;
  }
  & > div:nth-child(3) {
    grid-column: 3 / span 1;
    grid-row: auto;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(2/1fr);
    & > div:nth-child(1) {
      grid-column: 1 / span 1;
      grid-row: auto;
    }
    & > div:nth-child(2) {
      grid-column: 2 / span 1;
      grid-row: auto;
    }
    & > div:nth-child(3) {
      grid-column: 1 / span 2;
      grid-row: auto;
    }
  }
  @media screen and (max-width: 425px) {
    grid-template-columns: 1fr;
    & > div:nth-child(1) {
      grid-column: 1 / span 1;
      grid-row: auto;
    }
    & > div:nth-child(2) {
      grid-column: 1 / span 1;
      grid-row: auto;
    }
    & > div:nth-child(3) {
      grid-column: 1 / span 2;
      grid-row: auto;
    }
  }
`;

export const GroupCheck = styled.div`
  display: grid;
  grid-template-rows: repeat(2/1fr);
`;

// export const SignInCard = styled.div``;

export const SignUpNForgot = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const NavLink = styled(Link)`
  padding: 10px 0px;
  cursor: pointer;
  color: #202842;
  font-weight: 400;
  color: #32325d;
  font-weight: 700;
  text-decoration: underline;
  &:hover {
    color: #2b56f3;
  }

  /* justify-content: center;
  align-items: center; */
`;

export const ButtonsContainer = styled.div`
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
`;

export const ShortNote = styled.div`
  font-size: 16px;
  color: #32325d;

  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
  @media screen and (max-width: 425px) {
    font-size: 12px;
  }
`;
