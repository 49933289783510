import { USER_ACTION_TYPES } from "./user.types";

const INITIAL_STATE = {
  currentUser: null,
  credential: null,
  isLoading: false,
  authentication: false,
  resetPasswordSent: false,
  error: null,
  errorSignup: null,
  errorResetPassword: null,
};

export const userReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case USER_ACTION_TYPES.RESET_PASSWORD_START:
      return {
        ...state,
        error: null,
        resetPasswordSent: false,
        isLoading: true,
      };
    case USER_ACTION_TYPES.SIGN_UP_START:
    case USER_ACTION_TYPES.EMAIL_SIGN_IN_START:
      return { ...state, error: null, isLoading: true };
    case USER_ACTION_TYPES.SIGN_IN_SUCCESS:
    case USER_ACTION_TYPES.UPDATE_EMAIL_DATABASE_SUCCESS:
    case USER_ACTION_TYPES.UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        currentUser: payload,
        error: null,
        errorSignup: null,
        authentication: true,
        isLoading: false,
      };
    case USER_ACTION_TYPES.SIGN_OUT_SUCCESS:
      return {
        ...state,
        currentUser: null,
        error: null,
        errorSignup: null,
        authentication: false,
      };
    case USER_ACTION_TYPES.RE_AUTH_SUCCESS:
      return { ...state, credential: payload };
    case USER_ACTION_TYPES.RESET_PASSWORD_FAILED:
      return {
        ...state,
        errorSignup: null,
        error: null,
        errorResetPassword:
          "Your Password is incorrect or this account doesn't exist, Please try again!!",
        isResetSuccess: false,
        isLoading: false,
      };
    case USER_ACTION_TYPES.RE_AUTH_FAILED:
    case USER_ACTION_TYPES.SIGN_IN_FAILED:
      switch (payload.code) {
        case "auth/wrong-password":
          return {
            ...state,
            errorSignup: null,
            error: "The password is invalid",
            isResetSuccess: false,
            isLoading: false,
          };
        case "auth/user-not-found":
          return {
            ...state,
            errorSignup: null,
            error:
              "There is no existing user record corresponding to the provided identifier.",
            isResetSuccess: false,
            isLoading: false,
          };
        case "auth/too-many-requests":
          return {
            ...state,
            errorSignup: null,
            error:
              "Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later",
            isResetSuccess: false,
            isLoading: false,
          };
        default:
          return {
            ...state,
            errorSignup: null,
            error:
              "Your Password is incorrect or this account doesn't exist, Please try again!!",
            isResetSuccess: false,
            isLoading: false,
          };
      }
    case USER_ACTION_TYPES.AUTH_SUCCESS:
      return {
        ...state,
        authentication: true,
      };
    case USER_ACTION_TYPES.SIGN_OUT_FAILED:
      return { ...state, error: payload };
    case USER_ACTION_TYPES.SIGN_UP_FAILED:
      switch (payload.code) {
        case "auth/email-already-in-use":
          return {
            ...state,
            error: null,
            errorSignup: "The account already exists for that email.",
            isLoading: false,
          };
        case "auth/weak-password":
          return {
            ...state,
            error: null,
            errorSignup: "The password provided is too weak.",
            isLoading: false,
          };
        case "auth/operation-not-allowed":
          return {
            ...state,
            error: null,
            errorSignup: "There is a problem with auth service config :/",
            isLoading: false,
          };
        default:
          return {
            ...state,
            error: null,
            errorSignup: "Authenthication error, Please try again!!",
            isLoading: false,
          };
      }
    case USER_ACTION_TYPES.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        error: null,
        resetPasswordSent: true,
        isLoading: false,
      };
    default:
      return state;
  }
};
