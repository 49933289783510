import styled from "styled-components";
import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "../../assets/coaon_logo.svg";

export const NavigationContainer = styled.div`
  height: 100px;
  width: 100vw;
  display: flex;
  /* justify-content: space-between; */
  justify-content: flex-start;
  z-index: 998;
  padding: 0 200px;
  /* padding: 0.5rem calc((100vw - 1200px) / 2); */
  align-items: center;
  /* margin-left: 20%; */
  /* margin-bottom: 25px; */
  position: absolute;

  @media screen and (max-width: 1024px) {
    padding: 0 100px;
  }
  @media screen and (max-width: 768px) {
    padding: 0 50px;
  }

  @media screen and (max-width: 425px) {
    padding: 0 20px;
  }
  @media screen and (min-width: 1920px) {
    width: 1920px;
  }
  /* @media screen and (max-width: 800px) {
    height: 60px;
    padding: 10px;
    margin-bottom: 20px;
  } */
`;

export const LogoContainer = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  padding: 0 1rem;
  height: auto;
  cursor: pointer;
  /* height: 100%; */
`;

export const CoaonLogo = styled(Logo)`
  @media screen and (max-width: 768px) {
    /* width: 80%; */
    width: 60px;
  }
  @media screen and (max-width: 425px) {
    /* width: 80%; */
    width: 55px;
  }
`;

export const NavLinks = styled.div`
  display: flex;
  align-items: center;
  margin-right: -24px;

  width: 100vw;
  white-space: nowrap;
  /* justify-content: flex-end; */

  @media screen and (max-width: 768px) {
    /* width: 80%; */
    display: none;
  }
`;

export const NavLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    transition: all 0.2s ease-in-out;
    color: rgba(255, 255, 255, 0.6);
  }
  @media screen and (max-width: 1024px) {
    font-size: 12px;
  }
  /* justify-content: center;
  align-items: center; */
`;

export const SignInNavLink = styled(Link)`
  background-color: rgba(255, 255, 255, 0.44);
  min-width: auto;
  width: auto;
  height: 50px;
  letter-spacing: 0.5px;
  line-height: 50px;
  padding: 0 20px 0 20px;
  font-size: 15px;
  /* text-transform: uppercase; */
  font-weight: 700;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  /* border: 1px solid black; */
  &:hover {
    transition: all 0.2s ease-in-out;
    /* background-color: rgba(255, 255, 255, 0.6); */
    background-color: rgba(255, 255, 255, 0.6);
    color: white;
    border: none;
  }
  @media screen and (max-width: 1024px) {
    font-size: 12px;
    padding: 0 15px;
    height: 40px;
  }
  @media screen and (max-width: 768px) {
    padding: 0 50px;
  }

  @media screen and (max-width: 425px) {
    padding: 0 20px;
  }

  @media screen and (max-width: 768px) {
    /* width: 80%; */
    display: none;
  }
`;

export const SignInLink = styled.div`
  /* width: 50%;
  height: 100%; */
  display: flex;
  align-items: center;
  margin-right: 24px;

  justify-content: flex-end;
  width: 100vw;

  /* @media screen and (max-width: 800px) {
    width: 80%;
  } */
`;

export const OpenLinksButton = styled.button`
  /* width: 70px; */
  /* height: 80px; */

  /* color: white; */
  /* font-size: 35px; */

  display: none;
  color: #fff;
  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    background: none;
    border: none;
    top: 0;
    right: 0;
    transform: translate(-50%, 75%);
    font-size: 1.8rem;
    cursor: pointer;
  }
`;

export const NavbarContainer = styled.nav``;
