import { createSelector } from "reselect";

export const selectProductsReducer = (state) => state.products;

export const selectProducts = createSelector(
  [selectProductsReducer],
  (productsSlice) => productsSlice.products
);

export const selectProductsMap = createSelector([selectProducts], (products) =>
  Array.isArray(products)
    ? products.reduce((acc, docSnapshot) => {
        const { name, popular, price, subtitle, type, id, coverages } =
          docSnapshot;
        acc[name.toLowerCase()] = {
          popular,
          price,
          subtitle,
          name,
          type,
          id,
          coverages,
        };
        return acc;
      }, {})
    : 0
);

export const selectProductsIsLoading = createSelector(
  [selectProductsReducer],
  (product) => product.isLoading
);
