import styled from "styled-components";

export const PrivacyContainer = styled.div`
  position: relative;
`;

export const Wrapper = styled.div`
  top: 8%;
  padding: 20px 40px;
  position: relative;
  width: 100%;
  height: 180px;
  /* height: calc(100vw * (9 / 16)); */
  top: 0;
  left: 0;
  right: 0;
`;

export const PageTitle = styled.div`
  top: 8%;
  padding: 5% 10%;
  /* height: 300px; */
  position: absolute;
  z-index: 10;

  h1 {
    z-index: 10;
    padding-top: 20px;
    font-size: 60px;
    font-weight: 900;
  }
  @media screen and (max-width: 1024px) {
    padding: 6% 8% 0 8%;
    h1 {
      font-size: 38px;
    }
  }
  @media screen and (max-width: 425px) {
    padding: 10% 0 0 0;
    h1 {
      font-size: 28px;
    }
  }
`;

export const HeaderBG = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 300px;
  background: linear-gradient(
    62deg,
    rgba(134, 210, 245, 1) 0%,
    rgba(155, 166, 238, 1) 99%
  );
  @media screen and (max-width: 1024px) {
    height: 220px;
  }
  @media screen and (max-width: 425px) {
    height: 180px;
  }
`;

export const Content = styled.div`
  margin: auto;
  padding: 170px 10%;

  h1 {
    font-size: 30px;
    font-weight: 900;
    line-height: 1.5;
    padding: 10px 0;
    // float: inline-end;
  }
  h2 {
    text-transform: uppercase;
    color: #3e3ee0;
    font-size: 20px;
  }
  p {
    font-size: 18px;
    font-weight: 400;
    color: #697785;
    line-height: 1.5;
    text-align: justify;
  }

  @media screen and (max-width: 1024px) {
    padding: 100px 10%;
    /* padding-top: 100px; */
    /* padding-left: 100px;
    padding-right: 100px; */
    h1 {
      font-size: 25px;
    }
    h2 {
      font-size: 18px;
    }
    p {
      font-size: 16px;
    }
  }
  @media screen and (max-width: 768px) {
    h1 {
      font-size: 20px;
    }
    h2 {
      font-size: 16px;
    }
    p {
      font-size: 14px;
    }
  }
  @media screen and (max-width: 425px) {
    padding: 20px 10%;
    h1 {
      font-size: 18px;
    }
    h2 {
      font-size: 14px;
    }
    p {
      font-size: 12px;
    }
  }
`;

// .headerText {
//   margin: auto;
//   padding-top: 170px;
//   padding-left: 100px;
//   padding-right: 300px;
//   grid-area: missioninfo;
// }

// .sectionStyle {
// h1 {
//   font-size: 38px;
//   font-weight: 900;
//   line-height: 1.5;
//   padding: 10px 0;
//   // float: inline-end;
// }
// h2 {
//   text-transform: uppercase;
//   color: #3e3ee0;
// }
// p {
//   font-size: 20px;
//   font-weight: 400;
//   color: #697785;
//   line-height: 1.5;
// }
// }
