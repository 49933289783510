import styled from "styled-components";

import { ReactComponent as Stripe } from "../../assets/checkout/stripelogo.svg";
import { Link } from "react-router-dom";

export const CheckoutWrapper = styled.div`
  /* position: relative; */
  /* height: auto; */
  padding: 100px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  /* @media screen and (max-width: 768px) {
    padding: 100px 0;

  } */
`;

export const CheckoutContainer = styled.div`
  z-index: 10;
  padding: 20px;
  border-radius: 20px;
  width: 50%;
  position: relative;
  /* top: 50%; */
  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  box-shadow: rgba(149, 157, 165, 0.16) 0px 6px 12px;
  background-color: rgba(255, 255, 255, 0.6);
  & .checkIcon {
    display: flex;
    justify-content: center;
  }
  @media screen and (max-width: 1024px) {
    width: 80%;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const CheckoutHeader = styled.h1`
  text-align: center;
  color: #0b1538;
  @media screen and (max-width: 1024px) {
    font-size: 28px;
  }
  @media screen and (max-width: 768px) {
    font-size: 20px;
  }
  @media screen and (max-width: 425px) {
    font-size: 18px;
  }
`;

export const Group = styled.div`
  padding: 5px 20px;
  /* width: 500px; */
`;

export const GroupHeader = styled.h2`
  color: #0b1538;
  font-size: 18px;
  & span {
    color: #32325d;
    font-weight: 400;
  }

  @media screen and (max-width: 1024px) {
    font-size: 16px;
  }
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
  @media screen and (max-width: 425px) {
    font-size: 12px;
  }
`;

export const Line = styled.div`
  border-top: 2px dashed #1e2b5a;
  border-spacing: 10px;
  /* width: 500px; */
`;

export const ShortNote = styled.p`
  text-align: center;
  font-size: 12px;
  color: #32325d;
`;

export const StripeLogo = styled(Stripe)`
  width: 120px;
`;

export const PaymentFormContainer = styled.div`
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
`;

export const FormContainer = styled.div`
  padding: 1em;
  height: auto;
  width: 100%;
`;
export const CardElementContainer = styled.div`
  margin: 25px 0;
  box-shadow: rgba(149, 157, 165, 0.16) 0px 6px 12px;
  /* border: 3px solid gray; */
  padding: 1em;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 45px;
`;

export const ButtonContainerSucessful = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 45px;
`;

export const HeaderText = styled.h2`
  color: #0b1538;
  font-size: 18px;
`;

export const FilePreviewCard = styled.div`
  display: flex;
  background-color: white;
  border-radius: 25px;
  width: 100%;
  padding: 10px;
  justify-content: space-between;
  transition: all 0.5s cubic-bezier(0, 1, 1, 1);
  &:hover {
    cursor: pointer;

    transform: translateY(-3px);
  }
  & span {
    font-size: 14px;
    color: #32325d;
    font-weight: 400;
  }
`;

export const FilePreviewWrapper = styled.div`
  margin-top: 20px;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
`;

export const ErrorContext = styled.p`
  color: #ea5e5d;
`;

export const PaymentSuccessContext = styled.div`
  margin-top: 20px;
  line-height: 1.5;
  text-align: center;
  & h3 {
    /* text-align: center; */
    font-weight: 700;
    /* text-transform: uppercase; */
    color: #2b56f3;
    @media screen and (max-width: 1024px) {
      font-size: 18px;
    }
    @media screen and (max-width: 768px) {
      font-size: 16px;
    }
    @media screen and (max-width: 425px) {
      font-size: 14px;
    }
  }
  p {
    @media screen and (max-width: 1024px) {
      font-size: 16px;
    }
    @media screen and (max-width: 768px) {
      font-size: 14px;
    }
    @media screen and (max-width: 425px) {
      font-size: 12px;
    }
  }
  a {
    color: #2b56f3;
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const cardStyle = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: "Arial, sans-serif",
      fontSmoothing: "antialiased",
      fontSize: "18px",
      "::placeholder": {
        color: "#32325d",
      },
    },
    invalid: {
      fontFamily: "Arial, sans-serif",
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

export const NavLink = styled(Link)`
  padding: 10px 0;
  cursor: pointer;
  color: #32325d;
  font-weight: 700;
  text-decoration: underline;
  &:hover {
    color: #2b56f3;
  }
  /* justify-content: center;
  align-items: center; */
`;
