import Button from "../button/button.component";
import {
  ContactCardContainer,
  Card,
  Name,
  Description,
  Link,
  ButtonContainer,
  Icon,
} from "./contact-card.styles";

import { ReactComponent as WhatsappLogo } from "../../assets/contact/whatsapp-logo.svg";
import { ReactComponent as CallLogo } from "../../assets/contact/call-logo.svg";
import { ReactComponent as SupportLogo } from "../../assets/contact/support-logo.svg";
import { ReactComponent as InfoLogo } from "../../assets/contact/info-logo.svg";

const ContactCard = () => {
  return (
    <ContactCardContainer>
      <Card>
        <Icon>
          <SupportLogo />
        </Icon>
        <Name>Contact Support</Name>
        <Description>
          If you have any technical issue with your account or placing an order
        </Description>
        <ButtonContainer>
          <a href="mailto:support@coaon.net">
            <Button>Contact</Button>
          </a>
        </ButtonContainer>
      </Card>
      <Card>
        <Icon>
          <InfoLogo />
        </Icon>
        <Name>Contact For Info</Name>
        <Description>If you have any question about our packages</Description>
        <ButtonContainer>
          <a href="mailto:info@coaon.net">
            <Button>Contact</Button>
          </a>
        </ButtonContainer>
      </Card>
    </ContactCardContainer>
  );
};
export default ContactCard;

// <Card>
// <Icon>
//   <WhatsappLogo />
// </Icon>
// <Name>Whatsapp</Name>
// <Description> If you prefer to have a conversation with us</Description>
// <ButtonContainer>
//   <Button>Message Us</Button>
// </ButtonContainer>
// </Card>

// pic

// <Card>
// <Icon>
//   <CallLogo />
// </Icon>
// <Name>Call Us</Name>
// <Description>
//   If you have any inquiries and prefer timely response
// </Description>
// <ButtonContainer>
//   <a href="tel:123-456-7890">
//     <Button> Call Us</Button>
//   </a>
// </ButtonContainer>
// </Card>
