import styled from "styled-components";
import { Link } from "react-router-dom";
import { ReactComponent as SignInHeaderBg } from "../../assets/signin/signInBg.svg";

import { ReactComponent as CoaonLogo } from "../../assets/coaon_logo.svg";

export const SignInContainer = styled.div`
  /* background-color: #f6f9fc; */
  height: 100vh;
  width: 100%;
  z-index: 10;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: scroll;
  /* padding: 100px 10px; */
  @media screen and (max-width: 768px) {
    height: 100%;
    padding: 100px 10px;
    /* height: 100%;
    padding: 100px 10px; */
  }
`;

export const SignInBg = styled(SignInHeaderBg)`
  position: absolute;
  width: 100vw;
  height: 100%;
  /* height: 100%; */
  z-index: 1;
  top: 0;
  left: 0;
  @media screen and (min-width: 1920px) {
    /* width: 1920px; */
  }
`;

export const SignInCard = styled.div`
  /* position: absolute; */
  background-color: rgba(255, 255, 255, 0.36);
  box-shadow: 0px 10px 41px rgba(255, 172, 172, 0.33);
  border-radius: 25px;
  padding: 50px 80px;
  position: relative;
  /* position: absolute; */
  /* top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  z-index: 10;
  /* margin: auto; */
  @media screen and (max-width: 1024px) {
    padding: 40px 50px;
  }
  @media screen and (max-width: 425px) {
    padding: 20px 30px;
  }
`;

export const Logo = styled(CoaonLogo)`
  position: absolute;
  z-index: 10;
  width: 90px;
  height: auto;
  top: 2%;
  left: 2%;
  @media screen and (max-width: 768px) {
    width: 70px;
    /* margin: 20px; */
  }
  @media screen and (max-width: 425px) {
    width: 60px;
  }
`;

export const LogoContainer = styled(Link)``;
