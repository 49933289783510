const FAQS_DATA = [
  {
    id: 1,
    question: "Do I need an account to use the services?",
    answer:
      "Yes. You need to create an account with ID and password to request and use any of our service packages. You can keep track of your activities as well as our progress on your request through your account. ",
  },
  {
    id: 2,
    question: "Can I delete my account?",
    answer:
      "If you wish to remove your account from our database, simply send us an email to support@coaon.net ",
  },
  {
    id: 3,
    question: "How long does it take to have my document finished?",
    answer:
      "We recommend you wait for 1-3 business days to have your document available. You will receive an email notification when it is ready to download. Just log into your dashboard and you will see your document has been there to download already.",
  },
  {
    id: 4,
    question: "Can I be offered one-one supporting services ?",
    answer:
      "Sure, when you request service with document improvement, your document will be surely worked on by one of COAON’s members. This member will get in touch with you if there is any concern on your document to guarantee the highest quality for yours.\nIf you prefer to talk with one of our members about your documents or any concern on your job application, you can send us an email at info@coaon.net to book the meeting with us. ",
  },
  {
    id: 5,
    question: "Can I cancel my request? ",
    answer:
      "You can cancel your service at any time providing COAON has not started processing your request yet.\nYou can log into your account and choose the service package you would like to cancel or reach us through email/phone number stated on our Website during business hours. You need to provide the corresponding order ID when canceling a request.",
  },
  {
    id: 6,
    question: "Can I get a refund if I cancel my request?",
    answer:
      "If you cancel your request before COAON starts processing yours, you can expect to receive a refund to the card stored in your profile within 5 business days.\nTo receive a refund for the cancel package, you need to provide the order ID correspondingly with the package.",
  },
  {
    id: 7,
    question: "How to place an order?",
    answer:
      "After logging in, you can go to the Price page and click on the package you would like to make a request.\nWe offer 5 packages of document support for your job applications:\n- Resume Checking\n- Resume/CV Writing\n- Cover Letter Writing\n- Online Portfolio Content Improvement\n- Resume + Cover Letter Writing",
  },
  {
    id: 8,
    question: "What should I do if I forget my password?",
    answer:
      "Just click onto the link Forget Your Password and type in the email you register your account with us, then hit the Reset Password button\n You will receive an email with instructions on how to achieve your password to access your account.",
  },
];
export default FAQS_DATA;
