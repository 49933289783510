import { useState } from "react";
import { CardBox, Question, Answer } from "./faqs.styles";

const FaqsCard = ({ item }) => {
  const [isOpen, setIsOpen] = useState(false);
  // const [selectElement, setSelectElement] = useState(0);
  const handleClick = (id) => {
    // console.log("isOpen: " + isOpen);
    setIsOpen(!isOpen);
    // setSelectElement(id);
    // console.log("isOpen [" + id + "]:" + isOpen);
    // console.log("click [" + id + "]:" + +id);

    // if (isOpen === id) {
    // setIsOpen((isOpen) => (isOpen === index ? null : index));
    // } else {
    // }
  };
  return (
    <CardBox
      className="card-image-container"
      layout
      layoutId={`card-container-${item.id}`}
      transition={{
        layout: {
          duration: 0.2,
          type: "spring",
          // damping: 100,
          // stiffness: 500,
        },
      }}
      style={{ boxShadow: " 0px 24px 44px rgba(0, 0, 0, 0.08)" }}
      isopen={isOpen ? 1 : 0}
      onClick={() => handleClick(item.id)}
    >
      <Question layout="position" layoutId={`card-question-${item.id}`}>
        <div className="faqsIcon">
          <span></span>
          <span></span>
        </div>
        <h2>{item.question}</h2>
      </Question>

      {isOpen && (
        <Answer
          layoutId={`answer-container-${item.id}`}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.2 }}
        >
          <p>{item.answer}</p>
        </Answer>
      )}
    </CardBox>
  );
};
export default FaqsCard;
