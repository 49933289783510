import styled from "styled-components";
import { Link } from "react-router-dom";
import { Link as LinkS } from "react-scroll";
import CloseIcon from "@mui/icons-material/Close";

export const NavigationTopContainer = styled.div`
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  background: #0b1538;
  display: grid;
  align-items: center;
  left: 0;
  top: 0;
  transition: 0.3s ease-in-out;
  opacity: ${({ isOpen }) => (isOpen ? "100%" : "0")};
  top: ${({ isOpen }) => (isOpen ? "0" : "-100%")};
`;

export const Close = styled(CloseIcon)`
  color: #fff;
`;

export const CloseWrapper = styled.div`
  position: absolute;
  top: 1.2rem;
  right: 1.5rem;
  background: transparent;
  font-size: 2rem;
  cursor: pointer;
  outline: none;
`;

export const NavlinkWrapper = styled.div`
  color: #fff;
`;

export const Navlink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  text-decoration: none;
  list-style: none;
  transition: 0.2 ease-in-out;
  color: #fff;
  cursor: pointer;

  &:hover {
    color: #2dc8f8;
    transition: 0.2 ease-in-out;
  }
  /* @media screen and (max-width: 480px) {
    font-size: 1rem;
  } */
`;

export const NavlinkBtnWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const NavlinkBtnLink = styled(Link)`
  border-radius: 50px;
  background: rgba(255, 255, 255, 0.44);
  white-space: nowrap;
  padding: 16px 40px;
  color: #fff;
  font-size: 1.5rem;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background-color: #2dc8f8;
    color: #fff;
    border: none;
  }
  /* @media screen and (max-width: 480px) {
    font-size: 1rem;
    padding: 10px 20px;
  } */
`;

export const NavlinkMenu = styled.ul`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(5, 80px);
  text-align: center;
  padding: 0;

  @media screen and (max-width: 480px) {
    grid-template-rows: repeat(5, 60px);
  }
`;
