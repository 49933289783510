import {
  ExperienceSection,
  Title,
  Sectiontitle,
  ExperienceContainer,
  Description,
  ErrorContainer,
  ResumeSample,
  ErrorContext,
  ErrorHeader,
  ErrorText,
} from "./experience.styles";

const Experience = () => {
  return (
    <ExperienceSection>
      <ExperienceContainer>
        <Sectiontitle>Experience</Sectiontitle>
        <Title>What You Will Get After Use Our Service</Title>
        <Description>
          If you choose <span>Checking, Resume writing</span>, or{" "}
          <span>Cover letter+Resume writing</span> packages, then your resume
          will be analyzed by both our professionals and technology. Errors from
          your resume will be highlighted along with explanation to help you see
          how much they can affect ATS when parsing information and recruiters
          when reading documents
        </Description>

        <ResumeSample />

        <Description>
          When your documents are ready to download, you will get detailed
          explanation along with highlighted errors. Each explanation will show
          what kind of error you have and how much this error can hurt your
          resume when ATS and recruiters evaluate your document
        </Description>

        <ErrorContainer>
          <ErrorContext>
            <ErrorHeader>Structure Error</ErrorHeader>
            <ErrorText>
              Your resume contains a table/textbox/column. Information included
              in this section will be parsed through ATS differently to what
              human eyes see from a document. As a result, recruiters will view
              incorrect information from your resume through their screens. They
              can make a wrong evaluation on your qualifications. It is better
              to avoid this format at all cost in your resume.
            </ErrorText>
          </ErrorContext>

          <ErrorContext>
            <ErrorHeader>Appearance Error</ErrorHeader>
            <ErrorText>
              Your resume has characters which are not supported in ATS. When
              AST scans your resume, any characters which are not ATS supported
              will not be reported to recruiters. Recruiters need to base on
              other ATS supported characters from your resume to make decisions.
              You should not choose unsupported characters for your resume to
              make sure recruiters can view all information correctly.
            </ErrorText>
          </ErrorContext>
          <ErrorContext>
            <ErrorHeader>Structure Error</ErrorHeader>
            <ErrorText>
              Recruiters are also experienced resume writer professionals. Lots
              of them set very high standards for resumes. Any information which
              does not follow resume standards can leave a very bad impression
              on recruiters. It is better to make all information in your resume
              professional, consistent, and neat to make recruiters view you as
              qualified candidates.s. It is better to avoid this format at all
              cost in your resume.
            </ErrorText>
          </ErrorContext>
          <ErrorContext>
            <ErrorHeader>Word Error</ErrorHeader>
            <ErrorText>
              Recruiters are very strict about the language written in resumes.
              They require everything to be professional and errorless. From
              what candidates have input to their documents, recruiters can
              somehow evaluate their knowledge and manners when working with
              important issues. Any hints of unprofessional manners from your
              resume can make recruiters give you a bad impression.
            </ErrorText>
          </ErrorContext>
        </ErrorContainer>
      </ExperienceContainer>
    </ExperienceSection>
  );
};

export default Experience;

//   <ExperienceBg />
