import Button from "../button/button.component";

import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { addItemToCart } from "../../store/cart/cart.action";

import {
  BaseProduct,
  PopularProduct,
  Name,
  SubTitle,
  PriceTag,
  List,
  ListGroup,
  PopularTag,
  ProductContainer,
  PriceSubTitle,
  ButtonContainer,
} from "./price-card.styled";

import { useDispatch, useSelector } from "react-redux";
import { selectCartItems } from "../../store/cart/cart.selector";

export const PRODUCT_TYPE_CLASSES = {
  base: "base",
  popular: "popular",
};

const getProduct = (productType = PRODUCT_TYPE_CLASSES.base) =>
  ({
    [PRODUCT_TYPE_CLASSES.base]: BaseProduct,
    [PRODUCT_TYPE_CLASSES.popular]: PopularProduct,
  }[productType]);

const PriceCard = ({ product, productType }) => {
  const CustomProduct = getProduct(productType);
  const dispatch = useDispatch();
  const cartItems = useSelector(selectCartItems);
  const navigate = useNavigate();
  const { name, price, subtitle, coverages, popular } = product;
  const goToCheckoutHandler = useCallback(() => {
    dispatch(addItemToCart(cartItems, product));
    navigate("/checkout");
  }, []);
  return (
    <CustomProduct>
      {popular && <PopularTag>Most Popular</PopularTag>}
      <ProductContainer>
        <Name>{name}</Name>
        <SubTitle>{subtitle}</SubTitle>
        <PriceSubTitle>price</PriceSubTitle>
        <PriceTag>${price}</PriceTag>
        <ListGroup>
          {coverages.map((item) => (
            <List key={item}>{item}</List>
          ))}
        </ListGroup>
      </ProductContainer>
      <ButtonContainer>
        <Button
          buttonType={popular ? "buy" : "buyinverted"}
          onClick={goToCheckoutHandler}
        >
          Buy Now
        </Button>
      </ButtonContainer>
    </CustomProduct>
    // <ProductCartContainer>
    // <Name>{name}</Name>
    // <SubTitle>{subtitle}</SubTitle>
    // <Price>{price}</Price>
    // <Button buttonType={BUTTON_TYPE_CLASSES.inverted}>Buy Now</Button>

    //   <img src={imageUrl} alt={`${name}`} />
    //   <Footer>
    //     <Name>{name}</Name>
    //     <Price>{price}</Price>
    //   </Footer>
    //   <Button

    //   >
    //     Add to card
    //   </Button>
    // </ProductCartContainer>
  );
};
export default PriceCard;

//  buttonType={BUTTON_TYPE_CLASSES.inverted}
// onClick={addProductToCart}
