import styled, { css } from "styled-components";

const subColor = "#0b1538";
const mainColor = "#0b1538";

export const shrinkLabelStyles = css`
  top: -18px;
  font-size: 12px;
  color: ${mainColor};
`;

export const FormInputLabel = styled.label`
  color: ${subColor};
  font-size: 16px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 14px;
  transition: 300ms ease all;
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
  @media screen and (max-width: 425px) {
    font-size: 12px;
  }

  ${({ shrink }) => shrink && shrinkLabelStyles};
`;

export const Input = styled.input`
  background: none;
  background-color: white;
  color: ${subColor};
  font-size: 18px;
  padding: 20px 10px 10px 5px;
  display: block;
  width: 100%;
  border: none;
  border-radius: 0;
  margin-top: 10px;
  box-shadow: rgba(149, 157, 165, 0.16) 0px 6px 12px;

  &:focus {
    border: 2px solid #2b56f3;
    outline: none;
  }

  &:focus ~ ${FormInputLabel} {
    ${shrinkLabelStyles};
  }

  &.input-error {
    /* padding: 0; */
    margin: 0;
    border: 2px solid #ea5e5d;
  }
  /* @media screen and (max-width: 1024px) {
    width: 80%;
  } */

  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
  @media screen and (max-width: 425px) {
    font-size: 14px;
  }
`;

export const Group = styled.div`
  position: relative;
  margin: 20px 0;
`;
