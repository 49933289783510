import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../store/user/user.selector";
import {
  Close,
  CloseWrapper,
  NavigationTopContainer,
  Navlink,
  NavlinkBtnLink,
  NavlinkBtnWrapper,
  NavlinkMenu,
  NavlinkWrapper,
} from "./navigation-top-drop.styles";

const NavigationTopDrop = ({ isOpen, toggle }) => {
  const currentUser = useSelector(selectCurrentUser);
  return (
    <NavigationTopContainer isOpen={isOpen} onClick={toggle}>
      <CloseWrapper onClick={toggle}>
        <Close />
      </CloseWrapper>
      <NavlinkWrapper>
        <NavlinkMenu>
          <Navlink to="/" onClick={toggle}>
            Home
          </Navlink>
          <Navlink to="/about" onClick={toggle}>
            About
          </Navlink>
          <Navlink to="/price" onClick={toggle}>
            Pricing
          </Navlink>
          <Navlink to="/contact" onClick={toggle}>
            Contact
          </Navlink>
        </NavlinkMenu>
        <NavlinkBtnWrapper>
          {currentUser ? (
            <NavlinkBtnLink to="/account/" onClick={toggle}>
              Account
            </NavlinkBtnLink>
          ) : (
            <NavlinkBtnLink to="/signin" onClick={toggle}>
              Sign In
            </NavlinkBtnLink>
          )}
        </NavlinkBtnWrapper>
      </NavlinkWrapper>
    </NavigationTopContainer>
  );
};
export default NavigationTopDrop;
