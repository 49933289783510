import styled from "styled-components";

export const TableWrapper = styled.div`
  max-width: 100%;
  margin-top: 80px;
  /* padding: 0.5rem calc((100vw - 1200px) / 2); */
  height: calc(100vh - 80px);

  .statusStyle {
    padding: 8px;
    border-radius: 5px;
    font-weight: 700;

    &.ready {
      color: #2dc8f8;
      background-color: rgba(45, 200, 248, 0.17);
    }

    &.active {
      color: #59aaa7;
      background-color: rgba(72, 161, 158, 0.17);
    }
    &.inProgress {
      color: #8c5dea;
      background-color: rgba(140, 93, 234, 0.17);
    }
    &.moreInfo {
      color: #eb9d32;
      background-color: rgba(248, 208, 76, 0.17);
    }
    &.canceled {
      color: #ea5e5d;
      background-color: rgba(234, 94, 93, 0.17);
    }
  }

  .price {
    color: #3377ff;
    font-weight: 700;
  }

  .downloadlink {
    .icon {
      font-size: 16px;
      margin-right: 3px;
    }
    a {
      display: flex;
      color: #2b56f3;
      /* justify-content: center; */
      align-items: center;
      font-size: 12px;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  @media screen and (max-width: 768px) {
    margin-top: 63px;
    /* padding: 0.5rem calc((100vw - 1200px) / 2); */
    height: calc(100vh - 60px);
    /* font-size: 12px; */
  }
`;
