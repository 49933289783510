import { createSelector } from "reselect";

export const selectCurrentUserReducer = (state) => state.user;

export const selectError = createSelector(
  [selectCurrentUserReducer],
  (currentUser) => currentUser.error
);
export const selectAuth = (state) => state.user.authentication;

export const selectCurrentUser = createSelector(
  [selectCurrentUserReducer],
  (user) => user.currentUser
);

export const selectCurrentUserIsLoading = createSelector(
  [selectCurrentUserReducer],
  (currentUser) => currentUser.isLoading
);

export const selectErrorSignUp = createSelector(
  [selectCurrentUserReducer],
  (currentUser) => currentUser.errorSignup
);

export const selectResetPasswordSent = createSelector(
  [selectCurrentUserReducer],
  (currentUser) => currentUser.resetPasswordSent
);

export const selectResetPasswordError = createSelector(
  [selectCurrentUserReducer],
  (currentUser) => currentUser.errorResetPassword
);
