import { useNavigate } from "react-router-dom";
import {
  IntroSection,
  Bg,
  HeaderPic,
  HeaderContainer,
  HeaderContent,
  StartButton,
} from "./header.styles";

const Header = () => {
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate("/price");
  };

  return (
    <IntroSection>
      <HeaderContainer>
        <HeaderContent>
          <h1>Affordable Resume Fixing for US Job </h1>
          <p>Get your resume ready within 1-3 business days</p>
          <StartButton onClick={handleNavigate}>Get Started</StartButton>
        </HeaderContent>

        <HeaderPic />
      </HeaderContainer>

      <Bg />
    </IntroSection>
  );
};

export default Header;
