import styled from "styled-components";

export const PriceContainer = styled.div`
  padding-bottom: 100px;
  position: relative;
`;

export const ContextStyle = styled.div`
  grid-area: contactInfo;
  /* margin: st; */
  padding: 100px;
  /* padding-left: 100px; */
  /* padding-right: 300px; */

  h1 {
    font-size: 38px;
    font-weight: 900;
    line-height: 1.5;
    padding: 10px 0;
    // float: inline-end;
  }
  h2 {
    text-transform: uppercase;
    color: #3e3ee0;
  }
  p {
    font-size: 18px;
    font-weight: 400;
    color: #697785;
    line-height: 1.5;
  }

  @media screen and (max-width: 1024px) {
    h1 {
      font-size: 28px;
    }
    h2 {
      font-size: 20px;
    }
    p {
      font-size: 16px;
    }
  }
  @media screen and (max-width: 768px) {
    h1 {
      font-size: 20px;
    }
    h2 {
      font-size: 18px;
    }
    p {
      font-size: 14px;
    }
  }
  @media screen and (max-width: 425px) {
    padding: 50px;
    h1 {
      font-size: 16px;
    }
    h2 {
      font-size: 14px;
    }
    p {
      font-size: 12px;
    }
  }
`;

export const PriceCardContainer = styled.div`
  padding: 0 50px;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(5, 1fr);

  @media screen and (max-width: 1024px) {
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(3, 1fr);

    & div:nth-child(3) {
      grid-column: 2;
      grid-row: 1/ 1;
    }
    /* grid-row: 2 / 4; */
  }

  @media screen and (max-width: 700px) {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: auto;
    & div:nth-child(3) {
      grid-column: 1;
      grid-row: 1/ 1;
    }
  }
  /* 
    grid-template-areas:
      "resume combine checking"
      "online cover";
    grid-template-rows: auto; 
   } */
`;
