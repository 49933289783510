import styled, { css } from "styled-components";
import { motion } from "framer-motion";

export const FaqsWrapper = styled.div`
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(2, 1fr);
  ul {
    padding: 0;
  }
  @media screen and (max-width: 768px) {
    grid-gap: 20px;
    grid-template-columns: 1fr;
  }
`;

export const CardBox = styled(motion.div)`
  display: block;
  background-color: white;
  white-space: pre-wrap;
  cursor: pointer;
  padding: 2rem 4rem;
  p {
    padding-top: 1rem;
    line-height: 1.5;
    font-size: 16px;
    color: #e4e4e5;
    font-weight: 300;
    text-align: justify;
  }

  transition: 300ms ease all;
  ${({ isopen }) => isopen && cardOpenStyles};
  @media screen and (max-width: 1024px) {
    padding: 2rem 2rem;
    p {
      font-size: 14px;
    }
  }

  @media screen and (max-width: 768px) {
    p {
      font-size: 12px;
    }
  }
`;

export const Question = styled(motion.div)`
  display: flex;
  h2 {
    color: #0b1538;
    font-size: 18px;
    font-weight: 600;
    padding-left: 10px;
  }
  .faqsIcon {
    color: #0b1538;
    /* background: #e74c3c; */
    width: 20px;
    height: 20px;
    border: 0;
    /* font-size: 1.5em; */
    position: relative;
    margin: auto 0;
    span {
      position: absolute;
      transition: 300ms;
      background: #0b1538;
      /* border-radius: 2px; */
    }
    span:first-child {
      top: 25%;
      bottom: 25%;
      width: 10%;
      left: 45%;
    }
    span:last-child {
      left: 25%;
      right: 25%;
      height: 10%;
      top: 45%;
    }
  }

  @media screen and (max-width: 1024px) {
    h2 {
      font-size: 16px;
    }
  }

  @media screen and (max-width: 768px) {
    h2 {
      font-size: 14px;
    }
  }
`;

export const Answer = styled(motion.div)`
  display: block;
  padding: 0 30px;
`;

export const cardOpenStyles = css`
  background-color: #0b1538;
  ${Question} h2 {
    color: white;
  }
  ${Question} .faqsIcon span {
    background: #ea5e5d;
    transform: rotate(90deg);
  }

  ${Question} .faqsIcon span:last-child {
    left: 50%;
    right: 50%;
  }
`;
