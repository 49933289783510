import { Fragment } from "react";
import { ReactComponent as HeaderBg } from "../../assets/about/about-bg.svg";
import { ReactComponent as AboutPicHeader } from "../../assets/about/peoplechat.svg";
import { ReactComponent as FolderSvg } from "../../assets/about/folder.svg";
import { ReactComponent as MeetSVG } from "../../assets/about/meet.svg";
import { ReactComponent as ReadySVG } from "../../assets/about/ready.svg";
import { ReactComponent as AtsSvg } from "../../assets/about/ats.svg";

import "./about.styles.scss";
import Footer from "../../components/footer/footer.component";

const About = () => {
  return (
    <div className="about-container">
      <div className="intro">
        <div className="header-container">
          <div className="PageTitle">
            <h1>About Us</h1>
          </div>
          <div className="headerText sectionStyle">
            <h2>Our Mission</h2>
            <h1>
              Reveal Hidden Qualifications Which Recruiters are Looking for in
              Candidates
            </h1>
            <p>
              Understand the difficulties in finding a job, COAON will work
              closely with each job seeker to improve quality for every job
              application until you are ready
            </p>
          </div>
          <div className="headerPic">
            <AboutPicHeader className="pic" />
          </div>
        </div>
        <HeaderBg className="wave" />
        <div className="sectionBg"></div>
      </div>

      <div className="Section2">
        <div className="section2-container">
          <FolderSvg className="folderSvg" />
          <div className="sectionStyle">
            <h2>Your Comfort</h2>
            <h1>How Can We Help Job Seekers</h1>
            <p>
              We offer lots of solutions for your own needs. You can learn to
              write the job applications yourself with details explanation from
              our video courses, or you can have your job applications prepared
              for you within couple days
            </p>

            <div className="offer">
              <MeetSVG className="icon" />
              <p>Meet your target job requirements</p>
            </div>
            <div className="offer">
              <AtsSvg className="icon" />
              <p>Accepted by ATS</p>
            </div>
            <div className="offer">
              <ReadySVG className="icon" />
              <p>Get ready within 1-3 business days</p>
            </div>
          </div>
        </div>
      </div>

      <div className="Section3">
        <div className="section3-container">
          <div className="sectionStyle">
            <h2>Writers</h2>
            <h1>Our Writers</h1>
            <p>
              Having been working with resumes and cover letters for more than 7
              years, our writers have built up strong understandings in ATS and
              resume techniques. Through interaction with job seekers and
              recruiters from different organizations, our writers have figured
              out common resume mistakes that job seekers often fall into and
              desired qualifications recruiters often seek from their
              candidates. Understanding that fact, our writers can work closely
              with each job seeker to highlight qualifications that recruiters
              are looking for in each job application.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default About;
