import {
  PrivacyContainer,
  Wrapper,
  PageTitle,
  HeaderBG,
  Content,
} from "./privacy.styled";
import Footer from "../../components/footer/footer.component";

export const Privacy = () => {
  return (
    <PrivacyContainer>
      <Wrapper>
        <PageTitle>
          <h1>Privacy Policy</h1>
        </PageTitle>
        <HeaderBG />
      </Wrapper>
      <Content>
        <h2>Last updated: June 1st, 2022 </h2>

        <p>
          CoAoN operates https://www.coaon.net website. This page informs you of
          our policies regarding the collection, use and disclosure of Personal
          Information we receive from users of the Site.
        </p>
        <p>
          We use your Personal Information only for providing and improving the
          Site. By using the Site, you agree to the collection and use of
          information in accordance with this policy.
        </p>

        <h1>User Content</h1>
        <p>
          You are solely responsible for all texts, documents, or other content
          or information uploaded, entered or otherwise transmitted by you in
          connection with your use of the Services and/or Software (“User
          Content”). User Content includes, among other things, information
          transmitted by you. COAON has no obligation to monitor any User
          Content and shall have no liability to you or any other person or
          entity with respect thereto, including, without limitation, liability
          with respect to any information (including your confidential
          information) contained in or apparent from any User Content. You
          warrant, represent and covenant that you own or have a valid and
          enforceable license to use all User Content, and that no User Content
          infringes, misappropriates or violates the rights (including, without
          limitation, any copyrights or other intellectual property rights) of
          any person or entity or any applicable law, rule or regulation of any
          government authority of competent jurisdiction.
        </p>

        <p>
          Also, COAON is not responsible for the loss, corruption or other
          changes to User Content. Without limiting the foregoing, any
          feature(s) of the Services and/or Software that may permit you to
          temporarily save or otherwise store User Content is offered for your
          convenience only and COAON does not guarantee that the User Content
          will be retrievable. You are solely responsible for saving, storing
          and otherwise maintaining User Content including by maintaining backup
          copies of your User Content on appropriate independent systems that do
          not rely on the Services and/or Software.
        </p>
        <p>
          You retain all right, title, and interest in and to your User Content.
          By uploading or entering any User Content, you give COAON (and those
          it works with) a nonexclusive, worldwide, royalty-free and fully-paid,
          transferable and sublicensable, perpetual, and irrevocable license to
          copy, store and use your User Content in connection with the provision
          of the Software and the Services and to improve the algorithms
          underlying the Software and the Services.
        </p>
        <p>
          COAON’s members only review your content if we believe our Terms and
          Conditions have been violated. In cases you requests our HR
          Professionals to work on your documents, COAON’s members will review
          and read your user content.
        </p>

        <h1>Information Collection And Use</h1>
        <p>
          While using our Site, we may ask you to provide us with certain
          personally identifiable information that can be used to contact or
          identify you. Personally identifiable information may include, but is
          not limited to your full name, email address, phone number, physical
          address, professional background.
        </p>
        <p>
          COAON also retrieve information from your payments when you complete a
          transaction with us. We use this information to enable and fulfill
          your transactions.
        </p>
        <p>
          Our server also automatically collects location information, IP
          address, browser type and settings, the date and time of use,
          information about browser configuration, cookie data, usage
          information, device information, type of software and hardware are
          using, to better understand and optimize our Site.
        </p>
        <p>
          COAON uses all collected information to administer our site and
          software/service for security, communication, marketing, statistical
          purposes.
        </p>

        <h1>Information Retrieval</h1>
        <p>This website contains information retrieved from O*NET</p>

        <h1>Log Data</h1>
        <p>
          Like many site operators, we collect information that your browser
          sends whenever you visit our Site ("Log Data"). This Log Data may
          include information such as your computer's Internet Protocol ("IP")
          address, browser type, browser version, the pages of our Site that you
          visit, the time and date of your visit, the time spent on those pages
          and other statistics.
        </p>
        <p>
          In addition, we may use third party services such as Google Analytics
          that collect, monitor and analyze this data for customer satisfaction
          improvement purposes.
        </p>

        <h1>Communications</h1>
        <p>
          We may use your Personal Information to contact you with newsletters,
          marketing or promotional materials and other information that relates
          to updates about our products and services, or your package plan. You
          may opt out or unsubscribe to such communication by clicking on the
          unsubscription link enclosed in our messages to you or changing your
          contact preferences from your own dashboard.
        </p>
        <p>
          Even when you unsubscribe from our newsletters, marketing or
          promotional information, you will continue to receive updates about
          your purchased packages and/or services.
        </p>

        <h1>Cookies</h1>
        <p>
          Like many sites, we use "cookies" to collect information. You can
          instruct your browser to refuse all cookies or to indicate when a
          cookie is being sent. However, if you do not accept cookies, you may
          not be able to use some portions of our Site. COAON uses cookies to
          deliver our Site, Software/Service and also relevant ads to you.
        </p>

        <h1>Security</h1>
        <p>
          The security of your Personal Information is important to us, but
          remember that no method of transmission over the Internet, or method
          of electronic storage, is 100% secure. While we strive to use
          commercially acceptable means to protect your Personal Information, we
          cannot guarantee its absolute security. You acknowledge that you are
          sending information at your own risk. Upon receiving your information,
          COAON will protect it on our servers. You should equip your device
          with security features to protect information security. We recommend
          that you also secure all devices you use to connect and/or interact
          with our site, service/software.
        </p>
        <p>
          COAON may reach out to you through your contact information
          preferences about any security concerns, threats, instructions, and
          updates.
        </p>

        <h1>Changes To This Privacy Policy</h1>
        <p>
          This Privacy Policy is effective as of June 1st, 2022 and will remain
          in effect except with respect to any changes in its provisions in the
          future, which will be in effect immediately after being posted on this
          page.
        </p>
        <p>
          We reserve the right to update or change our Privacy Policy at any
          time and you should check this Privacy Policy periodically. Your
          continued use of the Service after we post any modifications to the
          Privacy Policy on this page will constitute your acknowledgment of the
          modifications and your consent to abide and be bound by the modified
          Privacy Policy.
        </p>
        <p>
          If we make any material changes to this Privacy Policy, we will notify
          you either through the email address you have provided us, or by
          placing a prominent notice on our website.
        </p>

        <h1>Contact Us</h1>
        <p>
          If you have any questions about this Privacy Policy, please contact
          us.
        </p>
      </Content>

      <Footer />
    </PrivacyContainer>
  );
};
export default Privacy;
