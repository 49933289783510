import { useSelector } from "react-redux";
import { Outlet, Navigate, useLocation } from "react-router-dom";
import { selectCartItems } from "../../store/cart/cart.selector";

const GuardedCheckout = () => {
  const location = useLocation();
  const isCartItems = useSelector(selectCartItems);
  return isCartItems.length ? (
    <Outlet />
  ) : (
    <Navigate to="/price" state={{ from: location }} replace />
  );
};

export default GuardedCheckout;
