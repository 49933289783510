import {
  FooterContainer,
  CompanyInfo,
  ResourceInfo,
  ProductInfo,
  SocialIcon,
  NavLinks,
  NavLink,
  TopicName,
  LogoContainer,
  EndContainer,
  Logo,
  SocialContainer,
  LogoLink,
} from "./footer.styles";

import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

const Footer = () => {
  return (
    <FooterContainer>
      <CompanyInfo>
        <TopicName>Company</TopicName>
        <NavLinks>
          <NavLink to="/about">About</NavLink>
          <NavLink to="/contact">Contact</NavLink>
        </NavLinks>
      </CompanyInfo>
      <ResourceInfo>
        <TopicName>Resources</TopicName>
        <NavLinks>
          <NavLink to="/faqs">FAQs</NavLink>
          <NavLink to="/privacy">Privacy Policy</NavLink>
          <NavLink to="/terms">Terms of Service</NavLink>
        </NavLinks>
      </ResourceInfo>
      <ProductInfo>
        <TopicName>Product</TopicName>
        <NavLinks>
          <NavLink to="/price">Writing Packages</NavLink>
        </NavLinks>
      </ProductInfo>
      <SocialContainer>
        <LogoContainer>
          <LogoLink to="/">
            <Logo />
          </LogoLink>
        </LogoContainer>
        <SocialIcon>
          <a
            href="https://www.facebook.com/COAON-102952919223947"
            className="social-icon"
          >
            <FacebookIcon />
          </a>
          <a href="https://twitter.com/coaon18" className="social-icon">
            <TwitterIcon />
          </a>

          <a
            href="https://www.linkedin.com/company/coaon"
            className="social-icon"
          >
            <LinkedInIcon />
          </a>

          <a href="https://www.instagram.com/coaon18/" className="social-icon">
            <InstagramIcon />
          </a>
        </SocialIcon>
      </SocialContainer>
      <EndContainer>
        <p>@ {new Date().getFullYear()} COAON LLC. All rights reserved.</p>
      </EndContainer>
    </FooterContainer>
  );
};
export default Footer;

// <NavLink to="/blog">Blog</NavLink>
