import styled from "styled-components";

import { Link } from "react-router-dom";

import { ReactComponent as CoaonLogo } from "../../assets/coaon_logo.svg";
export const CheckOutContainer = styled.div`
  /* background: #f6f9fc; */
`;
export const CheckoutContext = styled.div`
  z-index: 10;
`;

export const SiginContainer = styled.div`
  height: 100vh;
  width: 100%;
  /* margin: auto; */
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 768px) {
    height: 100%;
    padding: 100px 10px;
    /* height: 100%;
    padding: 100px 10px; */
  }
  /* padding: 200px 10px; */
  /* @media screen and (max-width: 1024px) {
    padding: 100px 10px;
  } */
`;

export const Logo = styled(CoaonLogo)`
  position: absolute;
  z-index: 10;
  width: 90px;
  height: auto;
  top: 2%;
  left: 2%;

  @media screen and (max-width: 768px) {
    width: 70px;
  }
  @media screen and (max-width: 425px) {
    width: 60px;
  }
`;

export const LogoContainer = styled(Link)``;
