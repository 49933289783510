import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { ReactComponent as CoaonLogo } from "../../assets/coaon_logo.svg";

export const SidebarContainer = styled.div`
  /* min-height: 100vh; */
  height: 100%;
  background-color: #fff;
  /* flex: 1; */
  position: fixed;

  /* z-index: 999; */
  /* background-color: white; */
  /* position: fixed; */
  box-shadow: 0px 24px 44px rgb(0 0 0 / 8%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* transition: all 0.1s ease-in-out; */

  @media screen and (max-width: 768px) {
    /* transition: all 0.1s ease-in-out; */
    /* height: 100%; */
    width: 60px;
  }
`;
export const Top = styled.div`
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const Center = styled.div`
  /* height: 80%; */
  /* flex: 1; */
  justify-content: flex-start;
  ul {
    list-style: none;
    margin: 0;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    li {
      padding: 10px 15px;
      display: flex;
      align-items: center;
      /* justify-content: center; */
      border-radius: 25px;
      .icon {
        margin-right: 20px;
      }

      &:hover {
        background-color: #f8faff;
        .icon {
          color: #3377ff;
        }
        span {
          color: #3377ff;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    /* padding: 10px 10px; */
    ul {
      margin: 10px 0;
      padding: 0 10px;
    }
    li {
      justify-content: center;
    }

    ul li .icon {
      margin-right: 0px;
    }
  }
`;

export const Bottom = styled.div`
  margin-top: auto;
  margin-bottom: 35px;
  display: flex;
  /* align-items: center; */
  /* justify-content: flex-end; */

  ul {
    list-style: none;
    margin: 0;
    padding: 20px;
    li {
      padding: 15px 10px;
      display: flex;
      align-items: center;
      border-radius: 25px;
      .icon {
        margin-right: 20px;
        color: #ea5e5d;
      }
      span {
        color: #ea5e5d;
        font-weight: 700;
      }
    }
  }

  @media screen and (max-width: 768px) {
    /* padding: 10px 10px; */
    ul {
      padding: 0 10px;
    }
    li {
      justify-content: center;

      /* padding: 0; */
      /* padding: 10px 15px; */
    }

    ul li .icon {
      margin-right: 0px;
    }
  }
`;

export const Logo = styled(CoaonLogo)`
  /* position: absolute; */
  z-index: 10;
  width: 90px;
  height: auto;
  top: 2%;
  left: 2%;
  transition: all 0.1s ease-in-out;
  @media screen and (max-width: 768px) {
    transition: all 0.1s ease-in-out;
    width: 50px;
    /* margin: 20px; */
  }
  /* @media screen and (max-width: 425px) {
    width: 60px;
  } */
`;

export const LogoContainer = styled(NavLink)``;

export const NavLinks = styled.div``;

export const Link = styled(NavLink)`
  cursor: pointer;
  font-size: 18px;
  color: #848484;
  white-space: nowrap;
  /* justify-content: center;
  align-items: center; */
  &.active {
    li {
      background-color: #f8faff;
      .icon {
        color: #3377ff;
      }
      span {
        color: #3377ff;
      }
    }
  }

  &.base {
    li {
      background-color: yellow;
      .icon {
        color: #848484;
      }
      span {
        color: #848484;
      }
    }
  }
  @media screen and (max-width: 1024px) {
    font-size: 14px;
  }
`;

export const LinkSpan = styled.span`
  display: block;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;
