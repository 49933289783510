import { createSelector } from "reselect";

export const selectOrderReducer = (state) => state.orders;

export const selectOrderHistory = createSelector(
  [selectOrderReducer],
  (order) => order.orderHistory
);

export const selectOrderHistoryIsLoading = createSelector(
  [selectOrderReducer],
  (ordersSlice) => ordersSlice.isLoading
);

export const selectOrderHistoryError = createSelector(
  [selectOrderReducer],
  (order) => order.error
);
