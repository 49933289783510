import {
  PriceTableContainer,
  TableHeader,
  HeaderBlock,
  TableContentRow1,
  TableContentRow2,
  TableContentRow3,
  TableContentRow4,
  TableContentRow5,
  TableContentRow6,
  TableContentRow7,
  ContentBlock,
} from "./price-table.styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

const PriceTable = () => {
  return (
    <PriceTableContainer>
      <TableHeader>
        <HeaderBlock>
          <span></span>
        </HeaderBlock>
        <HeaderBlock>
          <span>Resume Checking</span>
        </HeaderBlock>
        <HeaderBlock>
          <span>Resume / CV Writing</span>
        </HeaderBlock>
        <HeaderBlock>
          <span>
            Cover Letter + <br />
            Resume
          </span>
        </HeaderBlock>
        <HeaderBlock>
          <span>Online Portfolio</span>
        </HeaderBlock>
        <HeaderBlock>
          <span>Cover Letters</span>
        </HeaderBlock>
      </TableHeader>

      <TableContentRow1>
        <ContentBlock>
          <span>Error Examination</span>
        </ContentBlock>
        <ContentBlock>
          <span>
            <FontAwesomeIcon icon={faCheckCircle} color="#2B56F3" />
          </span>
        </ContentBlock>
        <ContentBlock>
          <span>
            <FontAwesomeIcon icon={faCheckCircle} color="#EA5E5D" />
          </span>
        </ContentBlock>
        <ContentBlock>
          <span>
            <FontAwesomeIcon icon={faCheckCircle} color="#2DC8F8" />
          </span>
        </ContentBlock>
        <ContentBlock>
          <span>
            {" "}
            <FontAwesomeIcon icon={faCheckCircle} color="#0B1538" />
          </span>
        </ContentBlock>
        <ContentBlock>
          <span></span>
        </ContentBlock>
      </TableContentRow1>

      <TableContentRow2>
        <ContentBlock>
          <span>Error Improvement Suggestion</span>
        </ContentBlock>
        <ContentBlock>
          <span></span>
        </ContentBlock>
        <ContentBlock>
          <span>
            <FontAwesomeIcon icon={faCheckCircle} color="#EA5E5D" />
          </span>
        </ContentBlock>
        <ContentBlock>
          <span>
            <FontAwesomeIcon icon={faCheckCircle} color="#2DC8F8" />
          </span>
        </ContentBlock>
        <ContentBlock>
          <span></span>
        </ContentBlock>
        <ContentBlock>
          <span></span>
        </ContentBlock>
      </TableContentRow2>

      <TableContentRow3>
        <ContentBlock>
          <span>Skills Suggestion</span>
        </ContentBlock>
        <ContentBlock>
          <span>
            <FontAwesomeIcon icon={faCheckCircle} color="#2B56F3" />
          </span>
        </ContentBlock>
        <ContentBlock>
          <span>
            <FontAwesomeIcon icon={faCheckCircle} color="#EA5E5D" />
          </span>
        </ContentBlock>
        <ContentBlock>
          <span>
            <FontAwesomeIcon icon={faCheckCircle} color="#2DC8F8" />
          </span>
        </ContentBlock>
        <ContentBlock>
          <span>
            <FontAwesomeIcon icon={faCheckCircle} color="#0B1538" />
          </span>
        </ContentBlock>
        <ContentBlock>
          <span>
            <FontAwesomeIcon icon={faCheckCircle} color="#2B56F3" />
          </span>
        </ContentBlock>
      </TableContentRow3>

      <TableContentRow4>
        <ContentBlock>
          <span>Achievement & Experience Suggestion</span>
        </ContentBlock>
        <ContentBlock>
          <span>
            <FontAwesomeIcon icon={faCheckCircle} color="#2B56F3" />
          </span>
        </ContentBlock>
        <ContentBlock>
          <span>
            <FontAwesomeIcon icon={faCheckCircle} color="#EA5E5D" />
          </span>
        </ContentBlock>
        <ContentBlock>
          <span>
            <FontAwesomeIcon icon={faCheckCircle} color="#2DC8F8" />
          </span>
        </ContentBlock>
        <ContentBlock>
          <span>
            <FontAwesomeIcon icon={faCheckCircle} color="#0B1538" />
          </span>
        </ContentBlock>
        <ContentBlock>
          <span>
            <FontAwesomeIcon icon={faCheckCircle} color="#2B56F3" />
          </span>
        </ContentBlock>
      </TableContentRow4>

      <TableContentRow5>
        <ContentBlock>
          <span>Keyword Improvement</span>
        </ContentBlock>
        <ContentBlock>
          <span></span>
        </ContentBlock>
        <ContentBlock>
          <span>
            <FontAwesomeIcon icon={faCheckCircle} color="#EA5E5D" />
          </span>
        </ContentBlock>
        <ContentBlock>
          <span>
            <FontAwesomeIcon icon={faCheckCircle} color="#2DC8F8" />
          </span>
        </ContentBlock>
        <ContentBlock>
          <span>
            <FontAwesomeIcon icon={faCheckCircle} color="#0B1538" />
          </span>
        </ContentBlock>
        <ContentBlock>
          <span>
            <FontAwesomeIcon icon={faCheckCircle} color="#2B56F3" />
          </span>
        </ContentBlock>
      </TableContentRow5>

      <TableContentRow6>
        <ContentBlock>
          <span>Job Alignment Suggestion</span>
        </ContentBlock>
        <ContentBlock>
          <span></span>
        </ContentBlock>
        <ContentBlock>
          <span></span>
        </ContentBlock>
        <ContentBlock>
          <span>
            <FontAwesomeIcon icon={faCheckCircle} color="#2DC8F8" />
          </span>
        </ContentBlock>
        <ContentBlock>
          <span></span>
        </ContentBlock>
        <ContentBlock>
          <span></span>
        </ContentBlock>
      </TableContentRow6>

      <TableContentRow7>
        <ContentBlock>
          <span>ATS acceptance</span>
        </ContentBlock>
        <ContentBlock>
          <span></span>
        </ContentBlock>
        <ContentBlock>
          <span>
            <FontAwesomeIcon icon={faCheckCircle} color="#EA5E5D" />
          </span>
        </ContentBlock>
        <ContentBlock>
          <span>
            <FontAwesomeIcon icon={faCheckCircle} color="#2DC8F8" />
          </span>
        </ContentBlock>
        <ContentBlock>
          <span></span>
        </ContentBlock>
        <ContentBlock>
          <span>
            <FontAwesomeIcon icon={faCheckCircle} color="#2B56F3" />
          </span>
        </ContentBlock>
      </TableContentRow7>
    </PriceTableContainer>
  );
};
export default PriceTable;
