import styled from "styled-components";

export const PriceTableContainer = styled.div`
  width: 90%;
  padding: 5%;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 200px auto 0;
  background-color: white;

  @media screen and (max-width: 800px) {
    width: 90%;
  }
  @media screen and (max-width: 425px) {
    width: 100%;
  }
`;

export const TableHeader = styled.div`
  width: 100%;
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid darkgrey;
  padding: 30px 0;
`;

export const HeaderBlock = styled.div`
  text-transform: capitalize;
  width: 23%;
  color: #0b1538;
  font-weight: 600;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* &:last-child {
    width: 8%;
  } */

  @media screen and (max-width: 1024px) {
    font-size: 16px;
  }

  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
  @media screen and (max-width: 425px) {
    font-size: 10px;
  }
`;

export const TableContentRow1 = styled.div`
  width: 100%;
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid darkgrey;
`;
export const TableContentRow2 = styled.div`
  width: 100%;
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid darkgrey;
`;
export const TableContentRow3 = styled.div`
  width: 100%;
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid darkgrey;
`;
export const TableContentRow4 = styled.div`
  width: 100%;
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid darkgrey;
`;
export const TableContentRow5 = styled.div`
  width: 100%;
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid darkgrey;
`;
export const TableContentRow6 = styled.div`
  width: 100%;
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid darkgrey;
`;
export const TableContentRow7 = styled.div`
  width: 100%;
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
`;

export const ContentBlock = styled.div`
  text-transform: capitalize;
  width: 23%;
  color: #697785;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.2;
  padding: 20px 10px;
  text-align: center;
  @media screen and (max-width: 1024px) {
    font-size: 14px;
  }

  /* &:last-child {
    width: 8%;
  } */

  @media screen and (max-width: 768px) {
    font-size: 12px;
  }
  @media screen and (max-width: 425px) {
    font-size: 10px;
  }
`;
