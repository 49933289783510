import styled from "styled-components";
import { Link } from "react-router-dom";
export const LoginModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: inherit;

  h1 {
    font-size: 38px;
    font-weight: 900;
    text-transform: uppercase;
    margin: 0;
    /* line-height: 1.5; */
    /* padding: 10px 0; */
    // float: inline-end;
  }
  h2 {
    text-transform: uppercase;
    color: #3e3ee0;
    margin: 10px 0;
    font-size: 18px;
  }
  /* @media screen and (max-width: 320px) {
    width: 300px;
    padding: 20px;
  } */
`;
