import { ReactComponent as UploadIcon } from "../../../assets/home/upload.svg";
import { ReactComponent as ReadyIcon } from "../../../assets/home/ready.svg";
import { ReactComponent as PrepareIcon } from "../../../assets/home/prepare.svg";
import "./steps.styles.scss";

const Steps = () => {
  return (
    <div className="stepsSection">
      <div className="step-container">
        <h2>Get Your Document Ready in 3 Steps</h2>
        <div id="step1">
          <div className="eachStep one">
            <UploadIcon />
            <p>Upload Your Resume</p>
          </div>
        </div>

        <div id="step2">
          <div className="eachStep two">
            <PrepareIcon />
            <p>Wait for 1-3 business days </p>
          </div>
        </div>

        <div id="step3">
          <div className="eachStep three">
            <ReadyIcon />
            <p>Document is ready to use</p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Steps;
