import { useFormik } from "formik";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetPasswordStart } from "../../store/user/user.action";
import {
  selectCurrentUserIsLoading,
  selectResetPasswordError,
  selectResetPasswordSent,
} from "../../store/user/user.selector";
import { forgetPasswordSchema } from "../../validations/uploadValidation";
import Button from "../button/button.component";
import FormInput from "../form-input/form-input.component";
import {
  ButtonsContainer,
  EmailText,
  ForgetContainer,
  Header,
  ShortNote,
  SpanLink,
  SubHeaderText,
  SubHeaderWrapper,
  MailIcon,
} from "./forget-password.styles";

import { useNavigate } from "react-router-dom";
import Spinner from "../spinner/spinner.component";
const ForgetPassword = () => {
  const dispatch = useDispatch();
  const errormessage = useSelector(selectResetPasswordError);
  const [isResetSending, setisResetSending] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [resetemail, setResetEmail] = useState("");
  const isLoading = useSelector(selectCurrentUserIsLoading);
  const isResetSuccess = useSelector(selectResetPasswordSent);

  const navigte = useNavigate();
  useEffect(() => {
    if (isProcessing) {
      if (errormessage === null) {
        if (isResetSuccess) {
          setisResetSending(true);
          setIsProcessing(false);
        }
        // console.log("1");
      } else {
        setisResetSending(false);
        setResetEmail("");
        setIsProcessing(false);
        // console.log("2");
      }
    }
  }, [isResetSuccess]);

  const onSubmit = async (values, actions) => {
    // event.preventDefault();

    try {
      // const response = await signInAuthUserWithEmailAndPassword(
      //   email,
      //   password
      // );

      // console.log(values);
      setResetEmail(values.email);
      setIsProcessing(true);

      dispatch(resetPasswordStart(values.email));
      actions.resetForm();
      // actions.resetForm();
    } catch (error) {
      console.log("password reset failed", error);
      setisResetSending(false);
      setResetEmail("");
      actions.resetForm();
      setIsProcessing(false);
    }
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: {
        email: "",
      },
      validationSchema: forgetPasswordSchema,
      onSubmit,
    });
  return (
    <ForgetContainer>
      {isResetSending ? (
        <Fragment>
          <SubHeaderWrapper>
            <MailIcon />
            <SubHeaderText>Check Your Email</SubHeaderText>
          </SubHeaderWrapper>
          <Header>We have sent password reset link to:</Header>

          <EmailText>{resetemail}</EmailText>

          <ShortNote>
            If it doesn't arrive soon, Check your spam folder or{" "}
            <SpanLink
              onClick={() => {
                setisResetSending(false);
              }}
            >
              send email again
            </SpanLink>
          </ShortNote>
          <Button
            onClick={() => {
              navigte("/signin");
            }}
          >
            Back to Sign in
          </Button>
        </Fragment>
      ) : (
        <Fragment>
          <SubHeaderText>Forget Password</SubHeaderText>
          <h1>Reset Your Password</h1>
          <form onSubmit={handleSubmit}>
            <FormInput
              label="Email"
              type="email"
              required
              onChange={handleChange}
              name="email"
              value={values.email}
              onBlur={handleBlur}
              className={errors.email && touched.email ? "input-error" : ""}
            />
            {errors.email && touched.email && (
              <p className="error">{errors.email}</p>
            )}
            {errormessage && <p className="error">{errormessage}</p>}

            <ButtonsContainer>
              <Button type="submit">
                {isLoading ? <Spinner /> : <span>Reset password</span>}
              </Button>
            </ButtonsContainer>
          </form>
        </Fragment>
      )}
    </ForgetContainer>
  );
};

export default ForgetPassword;
