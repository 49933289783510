import styled from "styled-components";
// import { ReactComponent as ExperiencetBg } from "../../../assets/home/experienceBg.svg";
import { ReactComponent as resume } from "../../../assets/home/resume-sample.svg";

export const ExperienceSection = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  padding-top: 200px;
  padding-bottom: 5%;
  background-image: linear-gradient(180deg, #0c2540, #15549f);
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 250px;
    width: 100%;
    background-color: #fff;
    transform: skewY(5deg);
    transform-origin: right top;
  }

  @media screen and (max-width: 1024px) {
    /* height: 1900px; */
    padding-top: 100px;
    &:before {
      height: 100px;
    }
  }
  @media screen and (max-width: 768px) {
    /* height: auto; */

    /* &:before {
      height: 150px;
    } */
  }
`;

export const ExperienceContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  /* padding-bottom: 30%; */
  /* height: 400px; */
  width: 60%;
  height: auto;
  position: relative;
  z-index: 3;

  /* display: grid;
  justify-items: start;
  grid-template-rows: repeat(2, 1fr); */
  @media screen and (max-width: 1024px) {
    /* padding-bottom: 20%;
    top: 250px; */
    width: 70%;
  }

  @media screen and (max-width: 425px) {
    /* width: 80%; */
  }
`;

export const Sectiontitle = styled.h3`
  font-size: 25px;
  font-weight: 400;
  color: #2dc8f8;
  text-transform: uppercase;
  @media screen and (max-width: 1024px) {
    font-size: 20px;
  }
  @media screen and (max-width: 768px) {
    font-size: 18px;
  }
  @media screen and (max-width: 425px) {
    font-size: 14px;
  }
`;

export const Title = styled.h2`
  /* grid-area: header; */
  font-size: 38px;
  font-weight: 900;
  line-height: 1.5;
  color: #fff;

  @media screen and (max-width: 1024px) {
    font-size: 28px;
  }
  @media screen and (max-width: 768px) {
    font-size: 20px;
  }
  @media screen and (max-width: 425px) {
    font-size: 16px;
  }
`;

export const Description = styled.p`
  font-size: 18px;
  font-weight: 400;
  color: white;
  line-height: 1.5;
  & span {
    font-weight: 700;
    color: #2dc8f8;
  }
  @media screen and (max-width: 1024px) {
    font-size: 16px;
  }
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
  @media screen and (max-width: 425px) {
    font-size: 12px;
  }
`;

export const ResumeSample = styled(resume)`
  width: 100%;
  height: auto;
`;

export const ErrorContainer = styled.div`
  display: grid;
  grid-gap: 50px;
  grid-template-columns: repeat(2, 1fr);
  color: white;
  @media screen and (max-width: 425px) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
  }
`;

export const ErrorContext = styled.div`
  padding: 20px;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

export const ErrorHeader = styled.h4`
  font-size: 30px;
  font-weight: 700;
  @media screen and (max-width: 1024px) {
    font-size: 20px;
  }
  @media screen and (max-width: 425px) {
    font-size: 16px;
  }
`;

export const ErrorText = styled.p`
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
  @media screen and (max-width: 1024px) {
    font-size: 14px;
  }
  @media screen and (max-width: 768px) {
    font-size: 12px;
  }
  @media screen and (max-width: 425px) {
    font-size: 10px;
  }
`;
