import { useState, FormEvent, ChangeEvent, Fragment } from "react";

// import { useUser, AuthCheck } from "reactfire";
import { v4 as uuid } from "uuid";

import FormInput from "../form-input/form-input.component";

import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

import Button, { BUTTON_TYPE_CLASSES } from "../button/button.component";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faCircleXmark,
  faCircleExclamation,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";

import { StripeCardElement } from "@stripe/stripe-js";
import { selectCurrentUser } from "../../store/user/user.selector";
import {
  selectCartCount,
  selectCartItems,
  selectCartTotal,
} from "../../store/cart/cart.selector";

import { fetchFromAPI } from "../../utils/helpers";

import Upload from "../upload/upload.component";
import {
  CheckoutContainer,
  CheckoutHeader,
  Group,
  GroupHeader,
  Line,
  ShortNote,
  PaymentFormContainer,
  FormContainer,
  CardElementContainer,
  ButtonContainer,
  cardStyle,
  HeaderText,
  StripeLogo,
  FilePreviewCard,
  FilePreviewWrapper,
  ErrorContext,
  ButtonContainerSucessful,
  PaymentSuccessContext,
  CheckoutWrapper,
  NavLink,
} from "./checkout-form.styles";
import { useDispatch, useSelector } from "react-redux";
import SignIn from "../../pages/sign-in/sign-in.component";
import { auth, storage } from "../../utils/firebase/firebase.utils";

import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useEffect } from "react";

import { uploadSchema } from "../../validations/uploadValidation";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { emptyCart } from "../../store/cart/cart.action";
import { useNavigate } from "react-router-dom";
import {
  clearErrorOrder,
  saveOrderHistory,
} from "../../store/orders/orders.action";
import { selectOrderHistoryError } from "../../store/orders/orders.selector";

const defaultFormFields = {
  jobtarget: "",
  downloadLink: [] as object[],
};

const defaultErrorMessage = {
  jobtargetError: "",
  inputFileError: "",
  cardError: "",
};

const ifValidCardElement = (
  card: StripeCardElement | null
): card is StripeCardElement => card !== null;

const CheckoutForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(uploadSchema),
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  // stripe
  const stripe = useStripe();
  const elements = useElements();

  //Cart
  const cartItems: [] = useSelector(selectCartItems);
  const amount: number = useSelector(selectCartTotal);
  const quantity: number = useSelector(selectCartCount);
  const errorSaveOrderHistory = useSelector(selectOrderHistoryError);

  // current user
  const currentUser = useSelector(selectCurrentUser);
  // progress
  const [percents, setPercents] = useState([] as number[]);

  //default form
  const [formFields, setFormFields] = useState(defaultFormFields);
  const [orderId, setOrderId] = useState(uuid());
  const [listFile, setListFile] = useState([] as any);
  const [isProcessingPayment, setIsProcessingPayment] = useState(false);
  const [IsPayment, setIsPayment] = useState(false);
  const [errorMessage, setErrorMessage] = useState(defaultErrorMessage);
  const { jobtargetError, inputFileError, cardError } = errorMessage;

  const [errorOrder, setErrorOrder] = useState("");

  const { jobtarget, downloadLink } = formFields;

  // const storageListRef = ref(storage, `/${currentUser}/`);
  //orederId
  // console.log(orederId);

  const resetFormFields = () => {
    setFormFields(defaultFormFields);
    setListFile([] as any);
  };

  const paymentHandler = async (e: FormEvent<HTMLFormElement>) => {
    // e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    if (listFile.length === 0 || listFile === undefined) {
      setErrorMessage({
        ...errorMessage,
        inputFileError: "*** please upload your documents",
      });
      return;
    }

    if (listFile.length > 3) {
      // console.log("*** limit 3 files to upload");
      setErrorMessage({
        ...errorMessage,
        inputFileError: "*** limit 3 files to upload",
      });
      return;
    }

    setIsProcessingPayment(true);

    // console.log(formFields);

    // Clamp amount to Stripe min/max
    const validAmount = Math.min(Math.max(amount, 10), 9999999);

    const description = cartItems.map((item: any) => item.name);

    // Make the API Request
    const response = await fetchFromAPI("payments", {
      body: {
        uid: currentUser.uid,
        amount: (validAmount * 100).toFixed(0),
        receiptEmail: currentUser.email,
        description: description[0],
        orderId: orderId,
      },
    });

    const cardDetails = elements.getElement(CardElement);

    if (!ifValidCardElement(cardDetails)) return;

    const paymentResult = await stripe.confirmCardPayment(
      response.client_secret,
      {
        payment_method: {
          card: cardDetails,
        },
      }
    );

    if (paymentResult.error) {
      // console.log(paymentResult.error.message);
      // alert(paymentResult.error);
      setErrorMessage({
        ...errorMessage,
        cardError: `${paymentResult.error.message}`,
      });
      setIsProcessingPayment(false);
      // console.log(errorMessage);
    } else {
      if (paymentResult.paymentIntent.status === "succeeded") {
        // Upload Strorage

        // console.log("orderId: " + orderId);
        Array.from(listFile).map((file: any) => {
          // console.log("loop");
          const storageRef = ref(
            storage,
            `/${currentUser.uid}/${orderId}/upload/${file.name}`
          );

          // progress can be paused and resumed. It also exposes progress updates.
          // Receives the storage reference and the file to upload.
          const uploadTask = uploadBytesResumable(storageRef, file);

          uploadTask.on(
            "state_changed",
            (snapshot) => {
              const percent = Math.round(
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
              );

              // update progress
              setPercents([...percents, percent]);
            },
            (err) => console.log(err),
            () => {
              // download url
              getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                setFormFields((formFields) => ({
                  ...formFields,
                  downloadLink: [
                    ...formFields.downloadLink,
                    { filename: file.name, link: downloadURL },
                  ],
                }));
                // console.log(downloadURL);
              });
            }
          );
        });

        // console.log(formFields);
        // setIsUpload(true);
        // add Order to Firestore
      }
    }
    // return false;
  };

  // Handle File Change
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files[0]) {
      setListFile([...listFile, event.target.files[0]]);
    }
  };

  // Handle input Change
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setFormFields({ ...formFields, [name]: value });

    // setFormFields(!formFields.terms);
    // setFormFields(!formFields.newsletter);
  };

  const deleteFile = (event) => {
    const fileToDelete = Array.from(listFile).filter(
      (item, index) => index !== event
    );
    setListFile(fileToDelete);
  };

  const emptyCartHandler = () => {
    dispatch(emptyCart());
  };
  // add Order to Firestore
  useEffect(() => {
    if (downloadLink.length > 0 && isProcessingPayment === true) {
      if (downloadLink.length === listFile.length) {
        dispatch(
          saveOrderHistory(
            "orders",
            { jobtarget, downloadLink },
            orderId,
            currentUser.uid,
            currentUser.firstname,
            currentUser.lastname,
            currentUser.email,
            currentUser.phone,
            amount,
            cartItems
          )
        );
        setIsPayment(true);
        // console.log("0");
      }
    }
  }, [downloadLink]);

  // useEffect(() => {
  // if (IsPayment) {
  //   if (errorSaveOrderHistory !== null) {
  //     // console.log("1");
  //     setIsProcessingPayment(false);
  //     // setIsPayment(true);

  //     // emptyCartHandler();
  //     elements.getElement(CardElement).clear();
  //   }
  //  else if (errorSaveOrderHistory) {
  //   console.log("2");
  //   const refund = async () => {
  //     const response = await fetchFromAPI("refund", {
  //       body: {
  //         orderId: orderId,
  //       },
  //     });
  //     if (response.error) {
  //       // console.log("4");
  //       setIsProcessingPayment(false);
  //       alert(response.error);
  //     } else {
  //       // console.log("5");
  //       if (response.status === "succeeded") {
  //         setIsProcessingPayment(false);
  //       }
  //     }
  //   };
  //   refund();
  // } else {
  // }
  // }
  // }, [IsPayment, errorSaveOrderHistory]);

  return (
    <CheckoutWrapper>
      {!IsPayment ? (
        <CheckoutContainer>
          <CheckoutHeader>
            Input Your Information Before Processing
          </CheckoutHeader>
          <form onSubmit={handleSubmit(paymentHandler)}>
            <Group>
              <GroupHeader>
                Upload Resume File & More Information File Here
                <span> (support .doc .docx .pdf & limit 3 files)</span>
              </GroupHeader>

              <Upload
                type="file"
                onChange={handleFileChange}
                // accept=".docx, .pdf, .doc"
                htmlFor="formId"
                id="formId"
                label="Add File Here"
                name="upload"
                vali={register("upload")}
                require
              />
              <FilePreviewWrapper>
                {listFile &&
                  Array.from(listFile).map((item: any, index) => (
                    <FilePreviewCard key={index}>
                      <span>{item && item.name}</span>
                      <FontAwesomeIcon
                        icon={faCircleXmark}
                        color="#EA5E5D"
                        onClick={() => deleteFile(index)}
                      />
                    </FilePreviewCard>
                  ))}
                {(listFile.length === 0 || listFile.length > 3) && (
                  <ErrorContext>{errorMessage.inputFileError}</ErrorContext>
                )}

                {errors.upload && (
                  <ErrorContext>{errors.upload.message}</ErrorContext>
                )}
              </FilePreviewWrapper>
            </Group>
            <Group>
              <GroupHeader>Job Target</GroupHeader>
              <FormInput
                label="Your Job Target"
                type="text"
                // required
                onChange={handleChange}
                name="jobtarget"
                value={jobtarget}
              />
            </Group>

            <Line />
            <Group>
              <GroupHeader>
                You Chose:
                {cartItems.length ? (
                  cartItems.map((item: any) => (
                    <span key={item.id}>
                      {" "}
                      {item.name} ${amount}
                    </span>
                  ))
                ) : (
                  <span> No Product Selected!!!</span>
                )}
              </GroupHeader>
            </Group>

            <PaymentFormContainer>
              <FormContainer>
                <HeaderText>Credit Card / Debit Card:</HeaderText>
                <CardElementContainer>
                  <CardElement options={cardStyle} />
                </CardElementContainer>
                {cardError && <ErrorContext>{cardError}</ErrorContext>}

                <ButtonContainer>
                  <Button
                    isLoading={isProcessingPayment}
                    buttonType={BUTTON_TYPE_CLASSES.buy}
                  >
                    Pay $ {amount}
                  </Button>
                  <StripeLogo />
                </ButtonContainer>
              </FormContainer>
            </PaymentFormContainer>
            <ShortNote>
              By clicking the "Pay" button above, you are agreeing to our{" "}
              <NavLink to="/terms">Terms of Service</NavLink> and acknowledge
              that you have read our{" "}
              <NavLink to="/privacy">Privacy Policy</NavLink>
            </ShortNote>
          </form>
        </CheckoutContainer>
      ) : (
        <Fragment>
          {!errorSaveOrderHistory ? (
            <CheckoutContainer>
              <div className="checkIcon">
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  color="#04ff00c9"
                  size="3x"
                />
              </div>
              <CheckoutHeader>Payment Successful</CheckoutHeader>

              <PaymentSuccessContext>
                <GroupHeader>
                  Your orderNo:
                  <span> {orderId}</span>
                </GroupHeader>
                <h3>
                  *** Please check your email frequently during
                  <span> 3 business days</span> counting from the order date for
                  any updates and inquiries about your order
                </h3>
                <p>
                  if you have any concerns or need to make any update relating
                  to your documents, you can send us an email at{" "}
                  <span>
                    <a href="mailto:info@coaon.net">info@coaon.net.</a>
                  </span>{" "}
                  When contacting us via email, remember to provide the
                  corresponding order ID. We will not handle any changes to
                  original request without the order ID
                </p>
              </PaymentSuccessContext>
              <ButtonContainerSucessful>
                <Button
                  isLoading={false}
                  buttonType={BUTTON_TYPE_CLASSES.buy}
                  onClick={() => {
                    resetFormFields();
                    setIsPayment(false);
                    dispatch(emptyCart());
                    navigate("/account", { replace: true });
                    window.location.reload();
                  }}
                >
                  Go to Dashboard
                </Button>
              </ButtonContainerSucessful>
            </CheckoutContainer>
          ) : (
            <CheckoutContainer>
              <div className="checkIcon">
                <FontAwesomeIcon
                  icon={faCircleExclamation}
                  color="#EA5E5D"
                  size="3x"
                />
              </div>
              <CheckoutHeader>Payment Failed</CheckoutHeader>

              <PaymentSuccessContext>
                <p>Payment was unsuccessful. {errorSaveOrderHistory.message}</p>
              </PaymentSuccessContext>
              <ButtonContainerSucessful>
                <Button
                  isLoading={false}
                  buttonType={BUTTON_TYPE_CLASSES.buy}
                  onClick={() => {
                    setIsPayment(false);
                    setIsProcessingPayment(false);
                    dispatch(clearErrorOrder());
                    elements.getElement(CardElement).clear();
                  }}
                >
                  Try Again
                </Button>
              </ButtonContainerSucessful>
            </CheckoutContainer>
          )}
        </Fragment>
      )}
    </CheckoutWrapper>
  );
};

export default CheckoutForm;

// {Array.from(percents).map((each: any) => (
//   <p>{each} % done</p>
// ))}
