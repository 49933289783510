import styled from "styled-components";
import Button from "../../../components/button/button.component";
import { ReactComponent as ResumeIntro } from "../../../assets/Resume.svg";
import { ReactComponent as HeaderBg } from "../../../assets/home/header-bg.svg";

export const IntroSection = styled.div`
  position: relative;
  width: auto;
  height: calc(100vw * (9 / 16));
  top: 0;
  left: 0;
  right: 0;
  @media screen and (max-width: 700px) {
    height: 400px;
  }
  @media screen and (max-width: 425px) {
    height: 400px;
  }
`;

export const Bg = styled(HeaderBg)`
  position: relative;
  top: 0;
  z-index: 1;
  width: 100%;
  height: auto;
`;

export const HeaderContainer = styled.div`
  top: 8%;
  padding: 20px 40px;
  z-index: 10;
  position: absolute;
  display: grid;
  grid-template: 1fr / repeat(2, 1fr);
  /* align-items: center;
  justify-content: center; */
  @media screen and (max-width: 1024px) {
    top: 15%;
  }
  @media screen and (max-width: 768px) {
    top: 18%;
  }
  @media screen and (max-width: 700px) {
    top: 20%;
    grid-template: 1fr / repeat(1, 1fr);
    padding: 20px 10px;
  }
`;

export const HeaderContent = styled.div`
  margin: auto;
  width: 70%;
  h1 {
    font-size: 60px;
    font-weight: 900;
    line-height: 1.5;
    // float: inline-end;
  }
  p {
    font-size: 20px;
    font-weight: 400;
    color: #697785;
  }
  @media screen and (max-width: 1440px) {
    width: 75%;
    h1 {
      font-size: 45px;
      // float: inline-end;
    }
    p {
      font-size: 18px;
      font-weight: 400;
    }
  }
  @media screen and (max-width: 1024px) {
    width: 85%;
    h1 {
      font-size: 35px;
      // float: inline-end;
    }
    p {
      font-size: 16px;
      font-weight: 400;
    }
  }

  @media screen and (max-width: 768px) {
    h1 {
      font-size: 25px;
    }
    p {
      font-size: 14px;
    }
  }

  /* @media screen and (max-width: 700px) {
    width: 85%;
    h1 {
      font-size: 25px;
      // float: inline-end;
    }
    p {
      font-size: 14px;
    } 
  }*/
`;

export const HeaderPic = styled(ResumeIntro)`
  width: 800px;
  height: auto;
  z-index: 10;
  @media screen and (max-width: 1440px) {
    width: 600px;
  }
  @media screen and (max-width: 1024px) {
    width: 400px;
  }
  @media screen and (max-width: 768px) {
    width: 350px;
  }
  @media screen and (max-width: 700px) {
    /* width: 250px; */
    display: none;
  }
`;

export const StartButton = styled(Button)`
  margin-top: 40px;
  @media screen and (max-width: 425px) {
    margin: 30px auto;
  }
`;
// Button {
//   margin-top: 40px;
// }
