import { useLocation } from "react-router-dom";
import SignUpForm from "../../components/sign-up-form/sign-up-form.component";
import {
  SignUpContainer,
  LogoContainer,
  SignUpCard,
  SignUpBg,
  Logo,
} from "./sign-up.styles";

const SignUp = (props) => {
  // const nextpath = location.state?.nextpath;

  return (
    <SignUpContainer>
      <LogoContainer to="/">
        <Logo />
      </LogoContainer>
      <SignUpCard>
        <SignUpForm />
      </SignUpCard>
      <SignUpBg />
    </SignUpContainer>
  );
};

export default SignUp;
