import FaqsCard from "./faqsCard.component";

import { FaqsWrapper } from "./faqs.styles";
import FAQS_DATA from "./faqs-data";
import { useState } from "react";

const Faqs = () => {
  return (
    <FaqsWrapper>
      {FAQS_DATA.map((item) => (
        <ul key={item.id}>
          <FaqsCard item={item} />
        </ul>
      ))}
    </FaqsWrapper>
  );
};
export default Faqs;
