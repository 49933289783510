import styled from "styled-components";

export const DashboardContainer = styled.div`
  display: grid;
  width: 100%;
  height: 100%;
  /* grid-template-columns: 1fr 8fr; */
  grid-template-columns: 1fr;

  /* @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  } */
  /* overflow: scroll; */
  /* background-color: lightblue; */
`;

export const DashboardContent = styled.div`
  /* flex: 8; */
  background-color: #f5f5f5;
  /* width: 100vw; */
  height: 100%;
  margin-left: 170px;
  /* grid-template-rows: 1fr 8fr; */
  grid-template-rows: 1fr;
  display: grid;

  @media screen and (max-width: 1024px) {
    margin-left: 160px;
  }
  @media screen and (max-width: 768px) {
    margin-left: 60px;
    overflow-y: scroll;

    /* overflow: scroll; */
  }
`;
